import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";


function Micuenta(){
    const navigate = useNavigate();
    const routeParams = useParams();

    const [infoUsuarioLocal, setInfoLocal]      = useState({}); // Variables localstorage
    const [administradores, setAdministradores] = useState([]);
    const urlVolver   = "/admin/mi-cuenta/" + routeParams.username;
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////


        async function ObtenerAdministradores(){
            await axios.post("/obtener-admins")
            .then(async function(response){
                setAdministradores(response.data.usuarios);
            });
        }
        
        ObtenerAdministradores();

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


    // FORMULARIO /////////////////////////////
    const[input, setInput] = useState ({
        nombre: "",
        apellido: "",
        email: "",
        username: "",
        password: "",
        tipo: ""
    });
    function handleChange(event){
        const {name, value} = event.target;
        // Filtrar solo para el campo "username"
        const filteredValue = name === "username" 
            ? value.replace(/[^A-Za-z0-9]/g, '')  // Permitir solo letras y números sin espacios
            : value;  // Para otros campos, dejar el valor sin filtrar

        // guardar el valor previo.
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: filteredValue
            };
        });
    }
    // FORMULARIO /////////////////////////////

    
    
    async function crearAdministrador(event){
        event.preventDefault(); // evita el parpadeo predefinid


        await axios.post("/crear-usuario", input)
        .then(function(response){
            if(response.data.usuarioExiste===true){ navigate("/alerta", {state: {titulo:"El usuario existe", mensaje:"Este usuario ya existe, por favor seleccione otro.", urlMensaje: urlVolver, nombreBoton:"Volver a mi cuenta"} } );}
            else if(response.data.emailExiste===true){ navigate("/alerta", {state: {titulo:"El correo ya existe", mensaje:"Este correo ya existe, por favor seleccione otro.", urlMensaje: urlVolver, nombreBoton:"Volver a mi cuenta"} } );}
            else{
                navigate("/alerta", {state: {titulo:"Usuario creado correctamente", mensaje:"El usuario ha sido creado correctamente.", urlMensaje: urlVolver, nombreBoton:"Volver a mi cuenta"} } );
            }
        });
    }

    return(
        <div className='contenedorPaginasUsuario'>
            
 

            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Mi cuenta</h1>
                    </div>

                    {/* Formulario crear administradores */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">                   
                        <div className="container">
                            <h2 style={{textAlign: "center"}} >Registrar nuevos administradores</h2>
                            <p  className="mb-5" style={{textAlign: "center"}}>Ingresa los datos para crear un nuevo administrador. Ten en cuenta los diferentes tipos de usuario a continuación:</p>

                            <ul>
                                <li><strong>Administrador:</strong> puede acceder a toda la zona de administración.</li>
                                <li><strong>Camarógrafo:</strong> únicamente puede grabar los partidos.</li>
                                <li><strong>Camarógrafo editor:</strong> puede acceder a toda la zona de camarógrafo.</li>
                                <li><strong>Narrador:</strong> solo puede acceder a la zona de grabación de voz de camarógrafo.</li>
                            </ul>

                            {/* FORMULARIO ////////////////////////////////////////////*/}
                            <form className="mt-5">
                                {/* Nombre */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.nombre} name="nombre" type="text" className="form-control" id="floatingInput" placeholder="nombre" />
                                    <label htmlFor="floatingInput">Nombre</label>
                                </div>

                                {/* Apellidos */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.apellido} name="apellido" type="text" className="form-control" id="floatingInput" placeholder="apellido" />
                                    <label htmlFor="floatingInput">Apellidos</label>
                                </div>
                                
                                {/* Email */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.email} name="email" type="text" className="form-control" id="floatingInput" placeholder="email" />
                                    <label htmlFor="floatingInput">Email</label>
                                </div>


                                {/* usuario */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.username} name="username" type="text" className="form-control" id="floatingInput" placeholder="username" pattern="[A-Za-z0-9]*"  title="Sin espacios, solo letras y números. Al menos 4 caracteres." />
                                    <label htmlFor="floatingInput">Usuario</label> 
                                </div>

                                {/* Rol de Usuario */}
                                <div className="form-floating mb-3">
                                    <select onChange={handleChange} value={input.role} name="tipo" className="form-select" id="floatingSelectRole" aria-label="Seleccionar rol">
                                        <option value="">Seleccionar tipo</option>
                                        <option value="Administrador">Administrador</option>
                                        <option value="Camarografo">Camarógrafo</option>
                                        <option value="Narrador">Narrador</option>
                                        <option value="CamarografoEditor">Camarógrafo editor</option>
                                    </select>
                                    <label htmlFor="floatingSelectRole">Tipo de Usuario</label>
                                </div>

                                {/* password */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.password} name="password" type="password" className="form-control" id="floatingInput" placeholder="username" />
                                    <label htmlFor="floatingInput">Contraseña</label>
                                </div>

                                

                                


                                {/* Botón enviar */}
                                <button onClick={crearAdministrador} type="submit" style={{width: "100%"}} className="btn btn-primary">Crear administrador</button>
                            </form>
                            {/* FORMULARIO ////////////////////////////////////////////*/}
                            </div>
                    </div>



                    {/* Administradores existentes */}
                    <div className="p-5 mb-4 bg-dark text-light rounded-3">
                        

                        {/* Nombre y texto */}
                        <div className="container-fluid py-5">
                            <h1 style={{textAlign: "center"}} >¡Hola!</h1>
                            <p style={{textAlign: "center"}}>Esta es la zona de tu cuenta. Aquí puedes ver toda la información referente a tus datos.</p>

                            
                            <div className="container mt-5">
                                <h3 style={{textAlign: "center"}}>Cuentas de administradores</h3>
                                <p  style={{textAlign: "center"}}>En esta sección puedes ver todos los usuarios tipo administrador en la base de datos.</p>


                                {/* Tabla de administradores */}
                                <div class="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Usuario</th>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Correo</th>
                                            </tr>
                                        </thead>

                                        <tbody id="productList">
                                        {(administradores)
                                        ?  administradores.map( (usuarioActual, index) =>{
                                            return(
                                                <tr key={index}>
                                                    {<th scope="row">{index + 1}</th> }
                                                    {<th scope="row">{usuarioActual.nombre + " " + usuarioActual.apellido}</th> }
                                                    {<th scope="row">{usuarioActual.username}</th> }
                                                    {<th scope="row">{usuarioActual.tipo}</th> }
                                                    {<th scope="row">{usuarioActual.email}</th> }
                                                </tr>
                                            )})
                                        :   <p>Aún no hay administradores</p>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>


                    </div>

                    
                </div>
            </div>

            
        </div>
    )
}

export default Micuenta;
import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import SidebarUsuario from '../../partials/sidebarUsuario';

 

function VerTorneoIndividualUsuario() {

  const navigate    = useNavigate();
  const location    = useLocation(); // cuando no se envía nada en state, location,state es null
  const routeParams = useParams();
  let { nombreTorneo }   = routeParams; // capturamos para paginación

  const [infoUsuarioLocal, setInfoLocal]= useState(null); // Variables localstorage
  const [avatar, setAvatar] = useState("");
  const [torneo, setTorneo] = useState(null);
  const [partidos, setPartidos] = useState(null);
  const [cargando, setCargando]  = useState(true);
  
  

  useEffect(() => {

    async function obtenerDeportes(){
      await axios.post("/obtener-torneo-global", {
        params: {nombreTorneo: nombreTorneo, fecha: ""}
      })
      .then(async function(response){
        if(response.status===200){ 
          setCargando(false); // quitamos la espera
          setTorneo(response.data.torneo); setPartidos(response.data.partidosEditados);
        }
        else{
          setCargando(false); // quitamos la espera
        }
      });
    }
    obtenerDeportes();



    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
    const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
    if(usuarioEncontrado){setInfoLocal(usuarioEncontrado); setAvatar(usuarioEncontrado.usuario.avatar);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
    else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

    // Modificar la clase de body par página de registro - styles.css /////////
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  
 
    
  
 
  
  return(
  <div className=''>
    <div className='d-flex flex-row '>

      {/* BARRA LATERAL */}
      <SidebarUsuario />

      {/* Columna 2 */}
      <div className="flex-grow-1 container-fluid">

        {/* Contenedor título  */}
        <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
          <h1 className="tituloPaginas h2">Videos</h1>

          {/* Contenedor para búsqueda y filtro */}
          {/* <ContenedorFiltros urlVolver={urlVolver} usuario={username} /> */}


          {/* Avatar y saludo */}
          <div className="d-flex align-items-center">
              <h4 className="textoHomeUsuario mx-2">Hola {(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: null}</h4>
              <img className="imgHomeUsuario" src={(infoUsuarioLocal) ? infoUsuarioLocal.usuario.avatar: null} alt="" />
          </div>
        </div>



        {(torneo)
          ? <div>
            {/* Primer contenedor: info del torneo*/}
            <div className='container'>
              <div className="p-5 my-4 bg-body-tertiary rounded-3">
                <div className="row  d-flex align-items-center" style={{ minHeight: "400px" }}>
                  {/* Imagen */}
                  <div className="col-lg-6 d-flex justify-content-center">
                          <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={(torneo) ? torneo.imagen : ""}  alt="" /></div>
                  </div>

                  {/* Nombre y texto */}
                  <div className="col-lg-6 d-flex justify-content-center">
                    <div className="container-fluid py-5">
                      <h1 className="display-5 fw-bold">Torneo {(torneo) ? torneo.nombre : ""}</h1>
                      <p className="col-md-8 fs-4">Deporte: {(torneo) ? torneo.deporte : ""}</p>
                        
                      {(torneo.video && torneo.video!=="")
                      ? <video
                        className="video-js vjs-default-skin videoIndividual"
                        preload="auto"
                        controls
                        data-setup="{}"
                        style={{ height: "200px"}}
                        onError={(e) => console.error("Error al cargar el video:", e)}
                        >
                          <source src={torneo.video} type="video/mp4" />
                      </video>
                      : null
                      }
                    </div>
                  </div>

                  <p className="">{torneo.descripcion}</p>
                </div>
              </div>
            </div>


            
            <div className='container mb-5' >
              
              {/* fechas */}
              <h3 className='text-center my-5'>Ver fechas</h3>
              {[...Array(torneo.cantidadDeFechas)].map((_, i) => (
                // Cada 3 elementos comenzamos una nueva fila
                i % 3 === 0 ? (
                  <div className="row" key={`row-${i}`}>
                    {[...Array(3)].map((_, j) => {
                      // Calcula el índice de la tarjeta actual
                      const index = i + j;
                      // Verifica si el índice no supera la cantidad de fechas disponibles
                      return index < torneo.cantidadDeFechas ? (
                        <div className="col-lg-4 mb-4" key={index}>
                          <div className="card">
                            <div className="card-header">{torneo.nombre}</div>
                            <div className="card-body">
                              <h5 className="card-title">Fecha {index + 1}</h5>
                              <p className="card-text">Videos de fecha  {index + 1}</p>
                              <a href={"/cliente/torneo-por-fecha/" + nombreTorneo + "/" + (index+1)} className="btn btn-primary w-100" > Ver fecha {index + 1} </a>
                            </div>
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                ) : null
              ))}
              
              {/* todos los partidos */}
              <hr className="featurette-divider" />
              <h3 className='text-center mb-5'>Todos los videos del torneo.</h3>
              <div className="row">
              {partidos ? (
              partidos.map((partidoActual, index) => {
              // Convertir los nombres de los equipos a minúsculas y reemplazar espacios por guiones
              const nombreEquipoUnoURL = partidoActual.nombreEquipoUno
                .toLowerCase()
                .replace(/\s+/g, '-');
              const nombreEquipoDosURL = partidoActual.nombreEquipoDos
                .toLowerCase()
                .replace(/\s+/g, '-');

              return (
                <div className="col-sm-4 col-lg-4" key={index}>
                  <div className="card m-1">
                    <div
                      className="card-header text-light"
                      style={{ backgroundColor: "#0E1465" }}
                    >
                      id: {partidoActual._doc.idPartido}
                    </div>
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <img
                        src={partidoActual.imagenEquipoUno}
                        alt=""
                        className="card-img-top imagenEquipos"
                      />
                      <span className="">vs</span>
                      <img
                        src={partidoActual.imagenEquipoDos}
                        alt=""
                        className="card-img-top imagenEquipos"
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        {partidoActual.nombreEquipoUno} vs {partidoActual.nombreEquipoDos}
                      </h5>
                      <p className="card-text">
                        <strong>Deporte:</strong> {partidoActual._doc.deporte}
                      </p>
                      {/* Enlace formateado con nombres en minúsculas y con guiones */}
                      <a
                        className="btn btn-primary w-100"
                        href={"/cliente/partido/" + partidoActual._doc.idPartido + "/" + nombreEquipoUnoURL + "-vs-" + nombreEquipoDosURL}
                        role="button"
                      >
                        Ver videos de Partido
                      </a>
                    </div>
                  </div>
                </div>
              );
              })
              ) : null}
              </div>

            </div>

          </div>
          :null
          }


      </div>


      {/* zona cargando */}
      {cargando && (
          <div className="overlay">
          <div className="loader"></div>
          <p className="loadingText">Buscando torneo, por favor espere...</p>
          </div>
      )}



    </div>
  </div>
  )
}



export default VerTorneoIndividualUsuario;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SidebarCamarografo from "../../partials/sidebarCamarografo";
import SeleccionarDispositivo from "./seleccionarDispositivo";

function TorneosGrabar(){

    
    const navigate    = useNavigate();
    const routeParams = useParams();
    let {username, pagina} = routeParams; // capturamos para paginación

    const [siguientePagina, setSiguientePagina]      = useState(null);
    const [paginaAnterior, setPaginaAnterior]        = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas]  = useState(null);
    const [infoUsuarioLocal, setInfoLocal]           = useState(null); // Variables localstorage
    const [torneos, setTorneos]                      = useState(null);
    const [cargando, setCargando]                    = useState(false);
 
    const urlVolver   = "/camarografo/grabar/" + routeParams.username + "/1";
 
    useEffect(() => {

        async function ObtenerTorneosGrabar(){
            setCargando(true);
            await axios.post("/obtener-torneos-grabar", {
                params: { pagina: pagina }
            })
            .then(async function(response){
                console.log(response);
                setCargando(false);
                setTorneos(response.data.torneos); setSiguientePagina(response.data.siguientePagina); setPaginaAnterior(response.data.paginaAnterior); setCantidadDePaginas(response.data.cantidadDePaginas)
            });
        }
        ObtenerTorneosGrabar();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);
    
    
    async function verFechasGrabar(datos) {
        let idTorneo = datos.idTorneo;
    
        // Si pasa todas las verificaciones, o si no es Android, proceder con la grabación
        let urlVerTorneo = "/camarografo/grabar/" + username + "/torneo/" + idTorneo;
        return navigate(urlVerTorneo);
    }
    
    
    async function atras(){
        let urlAver = "/camarografo/grabar/" + routeParams.username + "/" + paginaAnterior;
        navigate(urlAver);
        window.location.reload();
    }

    async function adelante(){
        let urlAver = "/camarografo/grabar/" + routeParams.username + "/" + siguientePagina;
        navigate(urlAver);
        window.location.reload();
    }

    

    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarCamarografo />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Grabar</h1>
                         {/* Avatar y saludo */}
                         <div className="d-flex align-items-center">
                            <h4 className="textoHomeUsuario mx-2">Hola {(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: null}</h4>
                        </div>
                    </div>

                    {(torneos)
                    ?<div  className="p-5 mb-4 rounded-3 bg-body-tertiary">
                        <h2 className=''>Torneos</h2>
                        <p className=''>Aquí podrás ver todos los torneos por grabar. Si deseas buscar uno en espacial, en la página home podrás buscar el partido por id.</p>
                        
                        {/* El total de columnas es: largo del arreglo / 4 o 12 /3, 4 */}
                        <div className="row">
                            {torneos.map((actual, index) => ( 
                                <div className="col-sm-4 col-lg-3" key={index}> 
                                    <div className="card m-1 my-5">
                                        <div className="card-header text-light" style={{backgroundColor: "#0E1465"}}>id: {actual.idTorneo}</div>
                                        <div className="d-flex justify-content-between align-items-center p-2">
                                            <img src={actual.imagen} alt="" className="card-img-top imagenTorneo" />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{actual.nombre}</h5>
                                            <p className="card-text">
                                                <strong>Deporte:</strong> {actual.deporte}
                                                <br />
                                                <strong>Partidos: </strong> {actual.partidos.length}
                                                <br />
                                                <strong>Fechas: </strong> {actual.cantidadDeFechas}
                                            </p>    

                                            <button
                                            className="w-100 btn btn-warning"
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                idTorneo: actual.idTorneo,
                                                };
                                                verFechasGrabar(datosAdicionales);
                                            }}
                                            >
                                            Ver fechas
                                            </button>  


                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :<p>En proceso</p>
                    }





                    {/* Botones paginación */}
                    <div className="row">
                        <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                        <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
                    </div>


                    {/* zona cargando */}
                    {cargando && (
                        <div className="overlay">
                        <div className="loader"></div>
                        <p className="loadingText">Completando proceso, por favor espere...</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TorneosGrabar;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SidebarCamarografo from "../../partials/sidebarCamarografo";
import SeleccionarDispositivo from "./seleccionarDispositivo";

function TorneoIndividualGrabar(){

    
    const navigate    = useNavigate();
    const routeParams = useParams();
    let {username, idTorneo} = routeParams; // capturamos para paginación

    const [siguientePagina, setSiguientePagina]      = useState(null);
    const [paginaAnterior, setPaginaAnterior]        = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas]  = useState(null);
    const [infoUsuarioLocal, setInfoLocal]           = useState(null); // Variables localstorage
    const [torneos, setTorneos]                      = useState(null);
    const [cargando, setCargando]                    = useState(false);
    const [torneo, setTorneo]                      = useState(null);
    const [fechas, setFechas] = useState(null);
    
 
    const urlVolver   = "/camarografo/grabar/" + routeParams.username + "/1";
 
    useEffect(() => {

        async function ObtenerFechasDeTorneo(){
            setCargando(true);
            await axios.post("/obtener-fechas-torneo-grabar", {
                params: { idTorneo: idTorneo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status === 200){
                    setFechas(response.data.fechas); setTorneo(response.data.torneo);
                }
                else{  navigate("/alerta", {state: {titulo:"Error", mensaje: response.data.mensaje, urlMensaje: urlVolver, nombreBoton:"Volver"} } );  }
            });
        }
        ObtenerFechasDeTorneo();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);
    
    

    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarCamarografo />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Grabar</h1>
                         {/* Avatar y saludo */}
                         <div className="d-flex align-items-center">
                            <h4 className="textoHomeUsuario mx-2">Hola {(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: null}</h4>
                        </div>
                    </div>

                   



                    {/* Primer contenedor: imagen principal */}
                    {(torneo) 
                    ? <div className='container'>
                        <div className="p-5 my-4 bg-body-tertiary rounded-3">
                            <div className="row  d-flex align-items-center" style={{ minHeight: "400px" }}>
                                {/* Imagen */}
                                <div className="col-lg-6 d-flex justify-content-center">
                                        <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={torneo.imagen}  alt="" /></div>
                                </div>

                                {/* Nombre y texto */}
                                <div className="col-lg-6 d-flex justify-content-center">
                                    <div className="container-fluid py-5">
                                        <h1 className="display-5 fw-bold">Torneo {torneo.nombre}</h1>
                                        <p className="col-md-8 fs-4">Deporte: {torneo.deporte}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}



                    {/* fechas */}
                    {torneo ? (
                    <div className="container mb-5 mt-3">
                        <h3 className="text-center my-5">Ver fechas</h3>
                        <p>Si ves que este torneo tiene fecha 0, es porque este torneo no se le han asignado fechas a los partidos.</p>

                        {/* Verifica si cantidadDeFechas es 0 */}
                        {torneo.cantidadDeFechas === 0 ? (
                        <div className="row">
                            <div className="col-lg-4 mb-4">
                            <div className="card">
                                <div className="card-header">{torneo.nombre}</div>
                                <div className="card-body">
                                <h5 className="card-title">Fecha 0</h5>
                                <p className="card-text">Videos de fecha 0</p>
                                <a href={"/camarografo/grabar/" + username + "/fecha/" + 0 + "/torneo/" + idTorneo } className="btn btn-primary w-100">
                                    Ver fecha 0
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        ) : (
                        [...Array(torneo.cantidadDeFechas)].map((_, i) => (
                            // Cada 3 elementos comenzamos una nueva fila
                            i % 3 === 0 ? (
                            <div className="row" key={`row-${i}`}>
                                {[...Array(3)].map((_, j) => {
                                // Calcula el índice de la tarjeta actual
                                const index = i + j;
                                const numeroFecha = index + 1;
                                // Verifica si el índice no supera la cantidad de fechas disponibles
                                return index < torneo.cantidadDeFechas ? (
                                    <div className="col-lg-4 mb-4" key={index}>
                                    <div className="card">
                                        <div className="card-header">{torneo.nombre}</div>
                                        <div className="card-body">
                                        <h5 className="card-title">Fecha {index + 1}</h5>
                                        <p className="card-text">Videos de fecha {index + 1}</p>
                                        <a href={"/camarografo/grabar/" + username + "/fecha/" + numeroFecha + "/torneo/" + idTorneo } className="btn btn-primary w-100">
                                            Ver fecha {index + 1}
                                        </a>
                                        </div>
                                    </div>
                                    </div>
                                ) : null;
                                })}
                            </div>
                            ) : null
                        ))
                        )}
                    </div>
                    ) : null}

        




                    {/* zona cargando */}
                    {cargando && (
                        <div className="overlay">
                        <div className="loader"></div>
                        <p className="loadingText">Completando proceso, por favor espere...</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TorneoIndividualGrabar;
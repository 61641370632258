import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import React, {useState, useEffect} from "react";


import '../estilos/partials.css';
function SidebarCamarografo(){

    const paramsUsuario = useParams(); // obtenemos params para poner en url
    const navigate = useNavigate();

    const [infoUsuarioLocal, setInfoLocal] = useState(null); // Variables localstorage

    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

    }, [navigate]);

    


    function handleLogOut(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        localStorage.clear(); // limpiamos el almacentaiento local.
        axios.post("/logout")
        .then(function(response){
            console.log(response);
            navigate("/");
        });
    }


    return(
        <div id='miSidebar' className="d-flex flex-column flex-shrink-0" style={{width: "4.5rem"}}>
        {(infoUsuarioLocal)
        ?<ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
            {/* Mostrar home a todos, menos a narrador  */}
            {(infoUsuarioLocal.usuario.tipo!=="Narrador") 
            ? <li className="nav-item">
                <a href={"/camarografo/home/" + paramsUsuario.username} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" aria-current="page" title="Home" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-house" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li>
            :null }
            
            {/* Mostrar Grabar a camarografo y camarografoEditor  */}
            {(infoUsuarioLocal.usuario.tipo!=="Narrador") 
            ?  <li>
                <a href={"/camarografo/grabar/" +paramsUsuario.username + "/1" } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Grabar" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-camera-video" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li>
            :null}
           
            {/* Mostrar completar procesos a camarografoEditor solamente  */}
            {(infoUsuarioLocal.usuario.tipo==="CamarografoEditor") 
            ? <li>
                <a href={"/camarografo/completar-procesos/" + paramsUsuario.username } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Completar procesos" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-arrow-clockwise" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li> 
            : null}
            
            {/* Mostrar grabar voz a narrador y a camarografoEditor  */}
            {(infoUsuarioLocal.usuario.tipo!=="Camarografo") 
            ?  <li>
                <a href={"/camarografo/grabar-voz-por-solicitud/" + paramsUsuario.username } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Grabar voz" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-record-circle" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li> 
                :null}
           
        </ul>
        : null}


 
            <div className="dropdown border-top">
            <a href="/" className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="/imagenes/marca/favicon.ico" alt="mdo" width="24" height="24" className="rounded-circle" />
            </a>
            <ul className="dropdown-menu text-small shadow">
                {/* <li><a className="dropdown-item" href={"/admin/mi-cuenta/" + paramsUsuario.username }>Mi cuenta</a></li> */}
                <li><hr className="dropdown-divider"/></li>
                <li>
                    <form>
                        <button onClick={handleLogOut} className="btn btn-light" type="submit">Cerrar Sesión</button>
                    </form>
                </li>
            </ul>
            </div>
        </div>
    )
}

export default SidebarCamarografo;
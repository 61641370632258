
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SidebarCamarografo from "../../partials/sidebarCamarografo";
import 'video.js/dist/video-js.css';
import SeleccionarDispositivo from "./seleccionarDispositivo";

function PartidoEncontrado(){

    
    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {username} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal] = useState(null); // Variables localstorage
    const [cargando, setCargando] = useState(false);
    const [camaras, setCamaras]                             = useState([]);
    const [microfonos, setMicrofonos]                       = useState([]);
    const [camaraSeleccionada, setCamaraSeleccionada]       = useState(null);
    const [microfonoSeleccionado, setMicrofonoSeleccionado] = useState(null);
    const [camaraDefault, setCamaraDefault]                 = useState(null);
    const [microfonoDefault, setMicrofonoDefault]           = useState(null);
    const [esPC, setEsPc]                                   = useState(null);
    
    const urlVolver   = "/camarografo/home/" + username;
    
    useEffect(() => {

        async function esPC(){
            const esMovil = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            const esPC = !esMovil;
            

            console.log("dispositivo identificado como pc: ", esPC);
            console.log("imprimir agent: ", navigator.userAgent);

            // Si es pc:
            if(esPC===true){
                setEsPc(true);

                // Obtener los dispositivos de entrada de video y audio disponibles
                const dispositivos = await navigator.mediaDevices.enumerateDevices();
                const camaras = dispositivos.filter(dispositivo => dispositivo.kind === 'videoinput');
                const microfonos = dispositivos.filter(dispositivo => dispositivo.kind === 'audioinput');
                setCamaraDefault(camaras[0].deviceId);
                setMicrofonoDefault(microfonos[0].deviceId);
                setCamaras( camaras);
                setMicrofonos(microfonos);
            } 
            else{setEsPc(false);}
        }
        esPC();

        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);
    
    
    function handleSeleccionarDispositivo(datos){
        // guardamos los dispositivos seleccionados
        setCamaraSeleccionada(datos.camaraSeleccionada);
        setMicrofonoSeleccionado(datos.microfonoSeleccionado);
    }

    async function videosPartido(datos){
        let urlAver = "/camarografo/ver-videos-partido/" + routeParams.username + "/1";
        navigate(urlAver, {state: {datos: datos} });
    }
    
    async function grabarVideo(datos) {
        let nombreEquipoUno = datos.nombreEquipoUno.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        let nombreEquipoDos = datos.nombreEquipoDos.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        let estadio = datos.estadio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        let torneo = datos.torneo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        
        const isAndroid         = /Android/i.test(navigator.userAgent);
        const minRAM            = 8; // Mínimo de 8 GB de RAM, con 11 excelente
        const minAndroidVersion = 10; // Mínimo Android 11
        // const minUploadSpeed    = 12; // 12 Mbps de subida (valor estimado)
        // const maxLatency        = 150; // 150 ms de latencia. Entre más baja mejor.    

        if (isAndroid) {
            // Calculo de RAM
            const deviceRAM = navigator.deviceMemory || 4; // Suponiendo 4 GB por defecto si no está disponible
            
            // Función para obtener la versión de Android
            const getAndroidVersion = () => {
                const userAgent = navigator.userAgent;
                const match = userAgent.match(/Android\s([0-9\.]*)/);
                return match ? parseFloat(match[1]) : 0;
            };
            const androidVersion = getAndroidVersion(); // Obtener la versión de Android
            console.log("Versión de Android: ", androidVersion);
            console.log("ram: ", deviceRAM);
            
            // Verificación de RAM
            if (deviceRAM < minRAM) {
                console.log("Dispositivo con RAM insuficiente: ", deviceRAM);
                return navigate("/alerta", {
                    state: {
                        titulo: "No puedes grabar",
                        mensaje: "Tu dispositivo no cuenta con la suficiente RAM. Por favor intenta con otro dispositivo con RAM mayor o igual a 8 GB. Tu RAM es: " + deviceRAM + " GB. O bien graba desde tu app de cámara y sube el video desde la función SUBIR VIDEO.",
                        urlMensaje: urlVolver,
                        nombreBoton: "Volver"
                    }
                });
            }
            // Verificación de versión de Android
            if (androidVersion < minAndroidVersion) {
                console.log("Versión de Android insuficiente: ", androidVersion);
                return navigate("/alerta", {
                    state: {
                        titulo: "No puedes grabar",
                        mensaje: "Tu dispositivo no cuenta con una versión de Android reciente. Por favor intenta con otro dispositivo. Tu versión es: " + androidVersion,
                        urlMensaje: urlVolver,
                        nombreBoton: "Volver"
                    }
                });
            }
        }
    
        // Si pasa todas las verificaciones, o si no es Android, proceder con la grabación
        let urlGrabar = "/grabando-ios/" + username + "/desdeCamarografo/" + datos.idPartido + "-" + nombreEquipoUno + "-" + nombreEquipoDos + "-" + (datos.dia) + "-" + (datos.mes) + "-" + (datos.año) + "-" + (estadio) + "-" + (torneo);
        navigate("/alerta-para-grabar", {
            state: {
                titulo: "Voltea tu celular y prueba tu internet",
                mensaje: "Necesitas tener un internet óptimo para grabar. Prueba que la velocidad de carga sea mayor a 12 Mbps. Haz click para probar. Si ya estás seguro, haz click en grabar ahora.",
                nombreBotonUno: "Test velocidad",
                nombreBotonDos: "Grabar ahora",
                urlBotonUno: "https://www.speedtest.net/es",
                urlBotonDos: urlGrabar,
                mostrarBotonUno: true,
                mostrarBotonDos: true,
                urlAfueraDeWeb: true, // si la url es afuera de la web, para usr target blank
                datosPagina: {
                    esPC: esPC,
                    datos: datos,
                    camaraSeleccionada: camaraSeleccionada,
                    microfonoSeleccionado: microfonoSeleccionado,
                    camaraDefault: camaraDefault,
                    microfonoDefault: microfonoDefault,
                    urlGrabar: urlGrabar
                }
            }
        });
    }
    

    return (
        <div className=''>
            <div className='d-flex flex-row '>
                {/* BARRA LATERAL */}
                <SidebarCamarografo />
    
                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark ">
                        <h1 className="h2">Partido</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                            {/* Seleccionar dispositivo */}
                            {(camaras.length>1)
                            ?<SeleccionarDispositivo camaras={camaras} microfonos={microfonos} onSeleccionarDispositivo={handleSeleccionarDispositivo} />
                            :null
                            }
                            </div>
                        </div>
                    </div>
    
                    {(location.state !== null && infoUsuarioLocal)
                        ? <div>


                            {/* Información del partido */}
                            <div id="" className="p-5 mb-4 rounded-3 bg-body-tertiary">
                                <div className="d-flex justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 text-center">
                                            <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={location.state.partido.imagenEquipoUno}></img>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={location.state.partido.imagenEquipoDos}></img>
                                        </div>
                                    </div>
                                </div>
                                <h2 className='text-center'>{location.state.partido.nombreEquipoUno + " vs " + location.state.partido.nombreEquipoDos}</h2>
                                <p  className='text-center'>{location.state.partido.torneo}</p>
                                <p  className='text-center'>{location.state.partido.nombreEstadio}</p>

                                {/* boton disponible para camarografoEditor y camarografo */}
                                {(infoUsuarioLocal.usuario.tipo!=="Narrador") 
                                ?<button
                                className="w-100 btn btn-primary"
                                id={location.state.partido._doc.idPartido}
                                onClick={(event) => {
                                    const datosAdicionales = {
                                    idPartido: location.state.partido._doc.idPartido,
                                    idEquipoUno: location.state.partido._doc.idEquipoUno,
                                    idEquipoDos: location.state.partido._doc.idEquipoDos,
                                    nombreEquipoUno: location.state.partido.nombreEquipoUno,
                                    nombreEquipoDos: location.state.partido.nombreEquipoDos,
                                    dia: location.state.partido._doc.fechaHora.dia,
                                    mes: location.state.partido._doc.fechaHora.mes,
                                    año: location.state.partido._doc.fechaHora.año,
                                    estadio: location.state.partido.nombreEstadio,
                                    torneo: location.state.partido.torneo
                                    };
                                    grabarVideo(datosAdicionales);
                                }}
                                >
                                Grabar Video
                                </button> 
                                : null
                                } 

                                {/* boton solo  disponible para camarografoEditor*/}
                                {(infoUsuarioLocal.usuario.tipo==="CamarografoEditor") 
                                ?<button
                                className="w-100 mt-1 btn btn-secondary"
                                id={location.state.partido.idPartido}
                                onClick={(event) => {
                                    const datosAdicionales = {
                                    idPartido: location.state.partido._doc.idPartido,
                                    idEquipoUno: location.state.partido._doc.idEquipoUno,
                                    idEquipoDos: location.state.partido._doc.idEquipoDos,
                                    nombreEquipoUno: location.state.partido.nombreEquipoUno,
                                    nombreEquipoDos: location.state.partido.nombreEquipoDos,
                                    imagenEquipoUno: location.state.partido.imagenEquipoUno,
                                    imagenEquipoDos: location.state.partido.imagenEquipoDos,
                                    dia: location.state.partido._doc.fechaHora.dia,
                                    mes: location.state.partido._doc.fechaHora.mes,
                                    año: location.state.partido._doc.fechaHora.año,
                                    estadio: location.state.partido.nombreEstadio,
                                    torneo: location.state.partido.torneo
                                    };
                                    videosPartido(datosAdicionales);
                                }}
                                >
                                Ver videos
                                </button>  
                                : null
                                }   
                            </div>
                            
                          
                        </div>
                        : <p>En proceso</p>
                    }
                </div>
            </div>
        </div>
    )
    
    
}

export default PartidoEncontrado;

import '../../estilos/paginas.css';
import React, {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';

 

function PagaYgraba() {

    const navigate    = useNavigate();

  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
 
  const [precios, setPrecios ] = useState({
    canchaUno: 35000,
    canchaDos: 55000,
})

async function pagar(){
    navigate("/iniciar-sesion");
}
    
  return(
    <div className='contenedorPaginasUsuario'>
      
        {/* Primer contenedor: imagen principal */}
        <div className='contenedorPAGA text-light'>
            <h1>Paga y graba</h1>
            <p className='subContenedorPrincipal'>Graba y muestra tu partido en cómo la vio</p>
        </div>


        {/* Sexto contenedor: FAQ */}
        <div className='container my-5'>
            
            {/* Realizar un pago */}
            <div className="p-5 mb-4 bg-body-tertiary rounded-3">
            <div className="container-fluid py-5 d-flex align-items-center">
                <div className="row w-100">
                    {/* Columna 1 */}
                    <div className="col-md-6 d-flex flex-column justify-content-center">
                    <h2 className="display-5 fw-bold">¡Realiza un pago aquí!</h2>
                    <p className="col-md-12 fs-4">
                        Si deseas grabar un partido para que se publique en Cómo la vio, el primer paso es realizar la compra de un partido.
                    </p>
                    </div>
                    {/* Columna 2 */}
                    <div className="col-md-6 d-flex justify-content-center">
                    <img className='mpImagen' src='/imagenes/webGlobal/mp.jpg' alt="Imagen de pago" />
                    </div>
                </div>
                </div>

                


                    {/* Contenedor de pago Uno */}
                    <div className="row gx-3 gy-2 align-items-center mb-1 py-3">

                        {/* Cantidad */}
                        <div className="col-sm-2">
                            <div className="form-floating">
                                <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="1"  />
                                <label htmlFor="floatingInputGrid">Cantidad</label>
                            </div>
                        </div>

                            {/* Producto */}
                            <div className="col-sm-2">
                            <div className="form-floating">
                                <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="Fútbol 5, 6, 7"  />
                                <label htmlFor="floatingInputGrid">Producto</label>
                            </div>
                        </div>


                        {/* Precio */}
                        <div className="col-sm-2">
                            <div className="form-floating">
                                <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={ "$ " + precios.canchaUno}  />
                                <label htmlFor="floatingInputGrid">Precio</label>
                            </div>
                        </div>

                        
                        
                        {/* Botón cambio de imagen */}
                        <div className="col-sm-6">
                            <button
                            className="w-100 btn btn-primary btn-lg"
                            onClick={(event) => {
                                const datosAdicionales = {
                                cantidad: 1,
                                valor: precios.canchaUno,
                                producto: "futbol678"
                                };
                                pagar(datosAdicionales);
                            }}
                            >
                            Pagar Fútbol 5, 6, 7
                            </button>
                        </div>
                    </div>

                    {/* Contenedor de pago Dos */}
                    <div className="row gx-3 gy-2 align-items-center mb-1 py-3">

                        {/* Cantidad */}
                        <div className="col-sm-2">
                            <div className="form-floating">
                                <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="1"  />
                                <label htmlFor="floatingInputGrid">Cantidad</label>
                            </div>
                        </div>

                            {/* Producto */}
                            <div className="col-sm-2">
                            <div className="form-floating">
                                <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="Fútbol 11"  />
                                <label htmlFor="floatingInputGrid">Producto</label>
                            </div>
                        </div>


                        {/* Precio */}
                        <div className="col-sm-2">
                            <div className="form-floating">
                                <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={ "$ " + precios.canchaDos}  />
                                <label htmlFor="floatingInputGrid">Precio</label>
                            </div>
                        </div>

                        
                        
                        {/* Botón cambio de imagen */}
                        <div className="col-sm-6">
                            <button
                            className="w-100 btn btn-primary btn-lg"
                            onClick={(event) => {
                                const datosAdicionales = {
                                cantidad: 1,
                                valor: precios.canchaDos,
                                producto: "futbol11"
                                };
                                pagar(datosAdicionales);
                            }}
                            >
                            Pagar Fútbol 11
                            </button>
                        </div>
                    </div>
            </div>

        </div>
    </div>
  )
}



export default PagaYgraba;
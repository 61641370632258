import styles from '../../estilos/inicioRegistro.module.css';
import {useParams, useSearchParams} from 'react-router-dom';
import { useEffect } from 'react';
import axios from "axios";

function AlertaMercadoPago() {
  // Hook para obtener los parámetros de búsqueda
  const [searchParams] = useSearchParams();
  const routeParams    = useParams();
  let {username, tipoAlerta} = routeParams; 

  
  // url promedio obtenida
  //http://localhost:3000/fallo/eduardo?collection_id=1327159341&collection_status=rejected&payment_id=1327159341&status=rejected&external_reference=null&payment_type=prepaid_card&merchant_order_id=23248389414&preference_id=89881914-d8ad9181-6a3f-4dd3-bc1c-f397bc030108&site_id=MCO&processing_mode=aggregator&merchant_account_id=null
  const collection_id       = searchParams.get('collection_id');
  const collection_status   = searchParams.get('collection_status');
  const payment_id          = searchParams.get('payment_id');
  const status              = searchParams.get('status');
  const external_reference  = searchParams.get('external_reference');
  const payment_type        = searchParams.get('payment_type');
  const merchant_order_id   = searchParams.get('merchant_order_id');
  const preference_id       = searchParams.get('preference_id');
  const site_id             = searchParams.get('site_id');
  const processing_mode     = searchParams.get('processing_mode');
  const merchant_account_id = searchParams.get('merchant_account_id');


  useEffect(() => {
  // async function AlmacenarPago(){
  //   await axios.post("/almacenar-pago", {
  //     params: { username: username, tipoAlerta: tipoAlerta, collection_id: collection_id, collection_status: collection_status, payment_id: payment_id, status: status, external_reference: external_reference, payment_type: payment_type, merchant_order_id: merchant_order_id, preference_id: preference_id, site_id: site_id, processing_mode: processing_mode, merchant_account_id: merchant_account_id     }
  //   })
  //   .then(async function(response){
  //       if(response.status===200){ 
  //         console.log("Datos almacenados");
  //       }
  //   });
  // }
  // AlmacenarPago();

  // No usaremos esta funci´no proque puede que el usaurio no vuelva, entonces usaremos webhooks para botener las actualzaicioens.
  }, []);



  const mensajeAlerta = tipoAlerta === "exito" 
  ? "¡Transacción exitosa!" 
  : tipoAlerta === "fallo" 
  ? "Transacción fallida" 
  : tipoAlerta === "pendiente" 
  ? "Transacción pendiente" 
  : "Estado desconocido";


  // Definir el color de fondo dinámicamente según el tipo de alerta
  const fondoAlerta = tipoAlerta === "exito" 
  ? {backgroundColor: "#01e401"}    
  : tipoAlerta === "fallo" 
  ? {backgroundColor: "red"}   
  : tipoAlerta === "pendiente" 
  ? {backgroundColor: "yellow"}  
  : "bg-light";   // Fondo claro por defecto

  return(
    <div style={fondoAlerta} className={styles.containerAletarMercadoPago} >
      <h1 className="my-5 ">{mensajeAlerta}</h1>
      <h3> {mensajeAlerta} Haz click para volver.</h3>

      <h4 className='mt-5 mb-2'>Datos de la tansacción</h4>
      <p>Collection ID: {collection_id}</p>
      <p>Collection Status: {collection_status}</p>
      <p>Payment ID: {payment_id}</p>
      <p>Status: {status}</p>
      <p>External Reference: {external_reference}</p>
      <p>Payment Type: {payment_type}</p>
      <p>Merchant Order ID: {merchant_order_id}</p>
      <p>Preference ID: {preference_id}</p>
      <p>Site ID: {site_id}</p>
      <p>Processing Mode: {processing_mode}</p>
      <p>Merchant Account ID: {merchant_account_id}</p>

      <p className='mt-5'><i>Haz click para completar la operación e iniciar a crear el partido para grabar</i></p>
      <a className="btn btn-primary btn-lg" href={"/cliente/pagos/" + username} role="button">Volver a pagos</a>
    </div>
  )
}


export default AlertaMercadoPago;
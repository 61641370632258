import '../estilos/partials.css';

function HeaderGlobal(){

    return(
        <div>
            <nav id="miNavBarGlobal" className="navbar navbar-expand-sm navbar-dark" aria-label="Third navbar example" >
                <div className="container-fluid">
                    {/* Imagen */}
                    <a className="navbar-brand" href="/" ><img src="/imagenes/marca/clvhorizontalblanco.png" alt="" height="50px" /></a>

                    {/* Botón adaptable */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    
                    <div className="collapse navbar-collapse" id="navbarsExample04">
                        {/* Menú principal */}
                        <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                            

                             {/* Dropdown Crear */}
                             <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-light" href={"#"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Ver videos
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item mi-drowpdown" href={"/ver-videos/futbol" }>Fútbol</a></li>
                                </ul>
                            </li>
                            <li className="nav-item"><a className="nav-link text-light" href="/paga-y-graba">Grabar</a></li>
                            <li className="nav-item"><a className="nav-link text-light" href="/faq">Faq</a></li>
                            <li className="nav-item"><a className="nav-link text-light" href="/contacto" >Contacto</a></li>
                        </ul>




                       {/* Segunda parte del menú */}
                        <div className="col-md-4 text-md-end text-center headerFinal">
                            <div className="dropdown">
                                <button className="btn btn-outline-w dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    Iniciar Sesión
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><a className="dropdown-item bg-blue" href="/registrar">Registrar</a></li>
                                    <li><div className="dropdown-divider"></div></li>
                                    <li><a className="dropdown-item mi-drowpdown" href="/iniciar-sesion">Usuario</a></li>
                                    <li><a className="dropdown-item mi-drowpdown" href="/iniciar-sesion-administrador">Admin</a></li>
                                    <li><a className="dropdown-item mi-drowpdown" href="/iniciar-sesion-camarografo">Camarógrafo</a></li>
                                </ul>
                            </div>
                        </div>



                    </div>
                </div>
            </nav>
        </div>
    )
}

export default HeaderGlobal;
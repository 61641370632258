
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SidebarCamarografo from "../../partials/sidebarCamarografo";
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import Etiquetas from "./etiquetas";
import SubirVideo from "./subirVideo";

function VerVideos(){

    
    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {username, pagina} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal] = useState({}); // Variables localstorage
    const [videosPartido, setVideosPartido] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [siguientePagina, setSiguientePagina] = useState(null);
    const [paginaAnterior, setPaginaAnterior] = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
    const urlVolver   = "/camarografo/home/" + username;

    

    
    useEffect(() => {


        async function ObtenerPartido(){
            setCargando(true);
            try {
                await axios.post('/ver-videos-partido', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: {  datos: location.state.datos, pagina: pagina }
                })
                .then(async function(response){
                    setCargando(false);
                    if(response.status===200){  
                        setVideosPartido(response.data.arregloFinal); setSiguientePagina(response.data.siguientePagina); setPaginaAnterior(response.data.paginaAnterior); setCantidadDePaginas(response.data.cantidadDePaginas)}
                });
            } 
            catch (error) { console.error('Error uploading video', error); }
        }
        ObtenerPartido();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);
    

    
    
    
    async function eliminarVideo(datos) {
        setCargando(true);

        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;

        try {
            await axios.post('/eliminar-video', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert('video eliminado.'); 
                }
            });
        } 
        catch (error) { console.error('Error convirtiendo video', error); }


    }
    
    async function atras(){
        // Debemos adjuntar la información del partido que está en local.state.datos
        let urlAver = "/camarografo/ver-videos-partido/" + routeParams.username + "/" + paginaAnterior;
        navigate(urlAver, {state: {datos: location.state.datos } });
        window.location.reload();
    }

    async function adelante(){
        // Debemos adjuntar la información del partido que está en local.state.datos
        let urlAver = "/camarografo/ver-videos-partido/" + routeParams.username + "/" + siguientePagina;
        navigate(urlAver, {state: {datos: location.state.datos } });
        window.location.reload();
    }
    
    async function anadirVoz(datos){
        let urlAver = "/camarografo/anadir-voz/" + routeParams.username;
        
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;

        navigate(urlAver, {state: {datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo  } });
    }

    async function finalizarProceso(datos){
        setCargando(true);
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;


        try {
            await axios.post('/finalizar-proceso-video', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert('video finalizado.'); 
                }
            });
        }catch(err){console.log(err);}
       
    }

    async function recortarPrimerSegundo(datos){
        setCargando(true);
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;


        try {
            await axios.post('/recortar-primer-segundo', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert(response.data.mensaje); 
                }
            });
        }catch(err){console.log(err);}
    }

    async function cambiarEquipo(datos){
        setCargando(true);
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;


        try {
            await axios.post('/cambiar-equipo', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert(response.data.mensaje); 
                }
            });
        }catch(err){console.log(err);}
    }

    async function anadirSonidEstadio(datos){
        setCargando(true);
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;


        try {
            await axios.post('/anadir-sonido-estadio', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert(response.data.mensaje); 
                }
            });
        }catch(err){console.log(err);}
    }

    const [videoTipos, setVideoTipos] = useState({});
    function handleChange(event, idVideo) {
        const { name, value } = event.target;
        // Permite letras, números y espacios
        const filteredValue = value.replace(/[^A-Za-z0-9\s]/g, '');
        
        setVideoTipos(prevTipos => ({
          ...prevTipos,
          [idVideo]: filteredValue
        }));
    }
    async function cambiarJugada(datos){
        setCargando(true);
        let urlVideo       = datos.urlVideo;
        let idVideo        = datos.idVideo;
        let jugadaAcambiar =  videoTipos[idVideo] || "";

        if (typeof jugadaAcambiar === 'undefined' || jugadaAcambiar === ""){ window.location.reload();  alert("Selecciona una jugada"); }
        else{
            try {
                await axios.post('/cambiar-jugada', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: {  datos: location.state.datos, urlVideo: urlVideo, jugadaAcambiar: jugadaAcambiar, idVideo: idVideo }
                })
                .then(async function(response){
                    setCargando(false);
                    if(response.data.status===200){ 
                        window.location.reload(); alert(response.data.mensaje); 
                    }
                });
            }
            catch(err){console.log(err);}
        }
    }

    return (
        <div className=''>

            <div className='d-flex flex-row '>
                {/* BARRA LATERAL */}
                <SidebarCamarografo />
    
                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark ">
                        <h1 className="h2">Ver videos de partido</h1>
                    </div>
     
                    {(location.state !== null)
                        ? <div>
                            {/* Información del partido */}
                            <div id="" className="container bg-body-tertiary p-5 mb-4 rounded-3" >
                                <div className="d-flex justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 text-center">
                                            <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={location.state.datos.imagenEquipoUno}></img>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={location.state.datos.imagenEquipoDos}></img>
                                        </div>
                                    </div>
                                </div>
                                <h2 className=' text-center'>{location.state.datos.nombreEquipoUno + " vs " + location.state.datos.nombreEquipoDos}</h2>
                                <p  className=' text-center'>{location.state.datos.torneo}</p>
                                <p  className=' text-center'>{location.state.datos.nombreEstadio}</p>



                                {/* Botón subir Video */}
                                <SubirVideo datos={location.state.datos} urlVolver={urlVolver} username={username} />
                                <div className="mt-3 text-center">
                                <a  href="https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/videos/funcion-subir-video.mp4" target="_blank" rel="noreferrer" style={{fontSize: "15px", textDecoration: "none", color: "black"}} >Si quieres saber cómo funciona <strong>haz click aquí</strong></a>
                                </div>
                            </div>
                            
                            {/* El total de columnas es: largo del arreglo / 4 */}
                            
                            <div id="contenedorVideos" className="row">
                                {(videosPartido.length > 0)
                                    ? videosPartido.map((videoActual, index) => (
                                        (videoActual.video.urlVideo !== "NA")
                                            ? <div key={index} className="col-md-3 mb-5 d-flex flex-column align-items-center">
                                                <div className="contenedor-video-grabacion">
                                                    <video
                                                        id={`video-${index}`}
                                                        className="video-js vjs-default-skin videoEnvVerVideos"
                                                        controls
                                                        preload="auto"
                                                        poster={videoActual.video.urlImagen}
                                                        data-setup="{ddd}"
                                                    >
                                                        <source src={`${videoActual.video.urlVideo}?v=${new Date().getTime()}`}  type="video/MP4" />
                                                    </video>

                                                    {/* Descripción del video */}
                                                    <p className="text-center mt-1" style={{fontSize: "13px"}}>id: {videoActual.video.idVideo}</p>
                                                    <div className="d-flex justify-content-center align-items-center my-2">
                                                        <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoActual.video.equipo}</strong></p>
                                                        <p className="textosDescripcionUno text-center me-2 mb-0">{videoActual.video.tipo}</p>
                                                        
                                                        <div className="d-flex align-items-center me-2">
                                                            <i className="textosDescripcionUno bi bi-heart me-1"></i>
                                                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.meGusta.length - 1}</p>
                                                        </div>
                                                        
                                                        <div className="d-flex align-items-center me-2">
                                                            <i className="textosDescripcionUno bi bi-play me-1"></i>
                                                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.vistas}</p>
                                                        </div>
                                                    </div>


                                                    

                                                    {/* Cambiar jugada */}
                                                    <form>
                                                    <div className="form-floating mb-1">
                                                        <select onChange={(event) => handleChange(event, videoActual.video.idVideo)} value={videoTipos[videoActual.video.idVideo] || ""} name="tipo" className="form-select" id={`floatingTipo-${videoActual.video.idVideo}`}>
                                                            <option value="">tipo</option>
                                                            <option value="atajada">atajada</option>
                                                            <option value="gol">gol</option>
                                                            <option value="oportunidad">oportunidad</option>
                                                        </select>
                                                        <label htmlFor={`floatingTipo-${videoActual.video.idVideo}`}>Nueva Juagada</label>
                                                    </div>
                                                    <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            event.preventDefault();  // Evita que se recargue la página
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo,
                                                            };
                                                            cambiarJugada(datosAdicionales);
                                                        }}
                                                    >
                                                        Cambiar jugada
                                                    </button>
                                                    </form>


                                                    <Etiquetas idVideo={videoActual.video.idVideo} etiquetasActuales={videoActual.etiquetas} />


                                                    {/* Cambiar equipo */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo 
                                                            };
                                                            cambiarEquipo(datosAdicionales);
                                                        }}
                                                    >
                                                        Cambiar equipo
                                                    </button>
                                                    {/* Recordar 1er segundo */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo 
                                                            };
                                                            recortarPrimerSegundo(datosAdicionales);
                                                        }}
                                                    >
                                                        Recortar 1er Segundo
                                                    </button>
                                                    {/* Botón añadir sonido estadio */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo 
                                                            };
                                                            anadirSonidEstadio(datosAdicionales);
                                                        }}
                                                    >
                                                        Añadir Sonido Estadio
                                                    </button>
                                                    {/* Botón añadir voz */}
                                                    {/* <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo 
                                                            };
                                                            anadirVoz(datosAdicionales);
                                                        }}
                                                    >
                                                        Añadir Voz
                                                    </button> */}
                                                    {/* Botón eliminar video */}
                                                    <button       
                                                        className="w-100 mt-1 btn btn-danger"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo 
                                                            };
                                                            eliminarVideo(datosAdicionales);
                                                        }}
                                                    >
                                                        Eliminar video
                                                    </button>
                                                    {/* Marcar como terminado */}
                                                    <button 
                                                        className={(videoActual.video.edicionFinalizada) ? "w-100 mt-1 btn btn-dark" : "w-100 mt-1 btn btn-success"}
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo 
                                                            };
                                                            finalizarProceso(datosAdicionales);
                                                        }}
                                                    >
                                                    {(videoActual.video.edicionFinalizada) ? "Ya está finalizado" : "Finalizar proceso"}
                                                        
                                                    </button>
                                                </div>
                                            </div>
                                            : null
                                    ))
                                    : <p className="text-center my-5" >No hay videos en edición.</p>
                                }
                            </div>

                            {/* Botones paginación */}
                            <div className="row">
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
                            </div>
                        </div>
                        : <p className="text-center my-5" >En proceso</p>
                    }
                </div>
            </div>


            {/* zona cargando */}
            {cargando && (
                <div className="overlay">
                <div className="loader"></div>
                <p className="loadingText">Completando proceso, por favor espere...</p>
                </div>
            )}
        </div>
    )
    
    
}

export default VerVideos;

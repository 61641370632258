import styles from '../estilos/inicioRegistro.module.css';
import React, {useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FooterInicioRegistro from '../partials/footerInicioRegistro';

 

function InicioSesion() {

    const navigate = useNavigate();

    const [infoUsuarioLocal, setInfoLocal]= useState({
        usuario: "",
        autenticado: false
    });

    const[input, setInput] = useState ({
        usuario: "",
        contraseña: ""
    });
    
    function handleChange(event){
        const {name, value} = event.target;
        // guardar el valor previo.
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        });
    }

    function handleClick(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        // crear objeto para pasar a servidor
        const nUsuario = {
            username: input.usuario,
            password: input.contraseña
        }

        // pasar datos a servidor o bd.
        axios.post("/iniciar-sesion", nUsuario, {withCredentials: "true"})
        .then(function (response) {
            if(response.data.existe===false){ navigate("/alerta", {state: {titulo:"Error de inicio", mensaje:"El usuario o la contraseña son incorrectos. Por favor, vuelve a intentar", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } ); }
            else if(response.data.esCliente===false){ navigate("/alerta", {state: {titulo:"Error de inicio", mensaje:"El usuario no es un cliente, por favor vuelva a iniciar sesión", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } ); }
            else{
                // guardamos en localStorage
                localStorage.setItem('infoUsuarioLocal', JSON.stringify({ usuario: response.data.usuarioEncontrado, autenticado: true}));

                // Creamos URL para redireccionar al home de usuario y Redireccionamos con los datos obtenidos del usuario
                var urlUsuario = "/cliente/home/" + response.data.usuarioEncontrado.username + "/NA/NA/NA/1";
                return navigate(urlUsuario);
            }
        });
    }

  

    return(
    <div className={styles.container}>
        <main className={styles['form-signin']}>
            <form>
            {/* Imagen y título */}
            <div className='d-flex justify-content-center'>
                <img className="my-5" src="/imagenes/marca/clvcazul.png" alt="" height="200" />
            </div>
            <h3 className="h3 mb-3 fw-normal text-light text-center">Iniciar sesión</h3>

            {/* usuario */}
            <div className="form-floating">
                <input onChange={handleChange} value={input.usuario} name="usuario" type="text" className="form-control"  placeholder="miusuario123" />
                <label htmlFor="floatingInput">Usuario</label>
            </div>


            {/* Contraseña  */}
            <div className="form-floating">
                <input onChange={handleChange} value={input.contraseña} name="contraseña" type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                <label htmlFor="floatingPassword">contraseña</label>
            </div>

            {/* Botón enviar */}
            <button onClick={handleClick} className="btn btn-primary w-100 py-2" type="submit">Iniciar Sesión</button>


            {/* footer */}
            <FooterInicioRegistro />                
            </form>
        </main>
    </div>
    )
}



export default InicioSesion;
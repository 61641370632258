import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
 

function AnaliticaPatrocinadores() {


    const [torneos, setTorneos] = useState(null);
    const [cargando, setCargando]  = useState(true);
    const [torneoSeleccionado, setTorneoSeleccionado] = useState(null);
    const [cantidadUsuarios, setCantidadUsuarios]     = useState(null);
    const [likesTotales, setLikesTotales]             = useState(null);
    const [vistasTotales, setVistasTotales]           = useState(null);
    const [partidosAusar, setPartidosAusar]           = useState(null);
  
    useEffect(() => {


    async function obtenerTorneos(){
        await axios.post("/obtener-torneos", {
            params: {deporte: "futbol"}
        })
        .then(async function(response){
            if(response.status===200){ 
            setCargando(false);
            setTorneos(response.data.torneos);
            }
        });
    }
    obtenerTorneos();

    // Modificar la clase de body par página de registro - styles.css /////////
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
    }, []);
  
 


    const[inputDos, setInputDos] = useState ({
    torneo: "",
    });

    function handleChange2(event) {
        const { name, value } = event.target;

        // Validar el valor antes de actualizar el estado
        setInputDos(prevInputDos => {
            return {
                ...prevInputDos,
                [name]: value
            };
        });
    }

    async function escogerTorneo(event){
        event.preventDefault();
        let idTorneo = inputDos.torneo;
        if(idTorneo === ""){
            alert("Debes seleccionar un torneo");
        }
        else{ 
            setCargando(true);  
            //1. CREAR PARTIDO COMPLETO
            await axios.post("/obtener-todo-torneo", {
                headers: { 'Content-Type': 'multipart/form-data',},
                params: {  idTorneo: idTorneo}
            })
            .then(function(response){  
                setCargando(false);
                if(response.status===200){ 
                    setTorneoSeleccionado(response.data.torneo);
                    setCantidadUsuarios(response.data.cantidadUsuarios);
                    setLikesTotales(response.data.likesTotales);
                    setVistasTotales(response.data.vistasTotales);
                    setPartidosAusar(response.data.partidosAusar); 
                }  
                else{window.location.reload(); alert("Error. Por favor volver a intentar");}
            });
        }

        
    }

    console.log(partidosAusar);
    
  return(
    <div className='contenedorPaginasUsuario'>
      
        {/* Primer contenedor: imagen principal */}
        <div className='contenedorANALITICA text-light'>
            <h1>Analítica</h1>
            <p className='subContenedorPrincipal'>Conoce aquí el estado de Cómo la vio</p>
        </div>


        {/* contenedor seleccionar torneo */}
        <div className='container my-5'>
            <div className="p-sm-5 p-3 mb-4 bg-dark rounded-3">
                <div className="container-fluid py-5">
                    <h1 className="display-5 fw-bold text-light">Selecciona el torneo</h1>
                    <p className="col-md-8 fs-4 text-light mb-3">Escoge un torneo para ver la analítica del torneo.</p>
                
                    {/* Dropdown torneo */}
                    {(torneos) 
                    ? <form>
                        <div className="form-floating mb-2">
                        <select onChange={handleChange2} className="form-select" id="torneo" name="torneo" value={inputDos.torneo} aria-label="Seleccione un torneo">
                            <option value="">Seleccionar torneo</option>
                            {torneos.map(torneo => (
                                <option key={torneo.idTorneo} value={torneo.idTorneo}>{torneo.nombre}</option>
                            ))}
                        </select>
                        <label htmlFor="torneo">Torneos</label>
                        </div>

                        {/* Botón escoger */}
                        <button type="submit" className="btn btn-warning w-100" onClick={escogerTorneo}>Escoger torneo</button>
                    </form>
                    : null}
                </div>
            </div>
        </div>




        {/* Contendeor torneo seleccionado */}
        {(torneoSeleccionado) 
        ? <div>

            {/* INFORMACIÓN DEL TORNEO */}
            <div className='container'>
                <div className="p-5 my-4 bg-body-tertiary rounded-3">
                    {/* FILA PRINCIPAL */}
                    <div className="row  d-flex align-items-center" style={{ minHeight: "400px" }}>
                    {/* Imagen */}
                    <div className="col-lg-6 d-flex justify-content-center">
                            <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={torneoSeleccionado.imagen}  alt="" /></div>
                    </div>

                    {/* Nombre y texto */}
                    <div className="col-lg-6 d-flex justify-content-center">
                        <div className="container-fluid py-5">
                        <h1 className="display-5 fw-bold">Torneo {torneoSeleccionado.nombre}</h1>
                        <p className="col-md-8 fs-4">Deporte: {torneoSeleccionado.deporte}</p>
                            
                        {(torneoSeleccionado.video && torneoSeleccionado.video!=="")
                        ? <video
                            className="video-js vjs-default-skin videoIndividual"
                            preload="auto"
                            controls
                            data-setup="{}"
                            style={{ height: "200px"}}
                            onError={(e) => console.error("Error al cargar el video:", e)}
                            >
                            <source src={torneoSeleccionado.video} type="video/mp4" />
                        </video>
                        : null
                        }
                        </div>
                    </div>

                    <p className="">{torneoSeleccionado.descripcion}</p>
                    </div>

                    {/* FILA REPRODUCCIONES, LIKES Y CANTIDAD DE USUARIOS */}
                    <div className="d-flex justify-content-around align-items-center my-3">
                    {/* Vistas */}
                    <div className="d-flex align-items-center me-3 p-3 bordeAnaliticas" >
                        <p className="textosDescripcionTorneoAnalitica text-center mb-0 me-1">Vistas totales</p>
                        <i className="textosDescripcionTorneoAnalitica bi bi-play me-1"></i>
                        <p className="textosDescripcionTorneoAnalitica text-center mb-0">{vistasTotales}</p>
                    </div>
                    
                    {/* Likes totales */}
                    <div className="d-flex align-items-center me-3 p-3 bordeAnaliticas" >
                        <p className="textosDescripcionTorneoAnalitica text-center mb-0 me-1">Likes</p>
                        <i style={{ color: "#3A58A5" }} className="textosDescripcionTorneoAnalitica bi bi-heart-fill me-1"></i>
                        <p className="textosDescripcionTorneoAnalitica text-center mb-0">{likesTotales}</p>
                    </div>
                    
                    {/* Usuarios totales */}
                    <div className="d-flex align-items-center p-3 bordeAnaliticas" >
                        <p className="textosDescripcionTorneoAnalitica text-center mb-0 me-1">Usuarios</p>
                        <i style={{ color: "#3A58A5" }} className="textosDescripcionTorneoAnalitica bi bi-person-circle me-1"></i>
                        <p className="textosDescripcionTorneoAnalitica text-center mb-0">{cantidadUsuarios}</p>
                    </div>
                    </div>
                </div>
            </div>



            {/* INFORMACIÓN TODOS LOS PARTIDOS */}
            <div className='container'>
                <div className="p-5 my-4 bg-body-tertiary rounded-3">
                    {/* Contenedor partidos individuales */}
                    {partidosAusar.map( (actual, key) => (
                        <div key={actual.partido.idPartido} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                            {/* id */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.partido.idPartido}  />
                                    <label htmlFor="floatingInputGrid">id</label>
                                </div>
                            </div>


                            {/* Partido */}
                            <div className="col-sm-5">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.equipoUno.nombre + " vs " + actual.equipoDos.nombre}  />
                                    <label htmlFor="floatingInputGrid">Partido</label>
                                </div>
                            </div>

                            {/* videos por partido */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.cantidadVideos}  />
                                    <label htmlFor="floatingInputGrid">Video</label>
                                </div>
                            </div>

                            {/* likes por partido */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.likesPorPartido}  />
                                    <label htmlFor="floatingInputGrid">Likes</label>
                                </div>
                            </div>


                            {/* Vistas por partido */}
                            <div className="col-sm-1">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.vistasPorPartido}  />
                                    <label htmlFor="floatingInputGrid">Vista</label>
                                </div>
                            </div>

                          
                           

                            {/* Video más viral */}
                            <div className="col-sm-2">
                                {actual.videoMasViral ? (
                                    actual.videoMasViral.urlVideo !== "NA" ? (
                                        <a 
                                            className={`btn btn-primary w-100 ${actual.videoMasViral === null ? 'disabled' : ''}`} 
                                            href={"video/" + actual.videoMasViral.idVideo +  "/" + actual.urlViral} 
                                            // href={actual.videoMasViral.urlVideo} 
                                            role="button" 
                                            target='_blank' 
                                            rel="noreferrer"
                                            onClick={e => {
                                                if (actual.videoMasViral.urlVideo === "NA" || actual.videoMasViral === null) {
                                                    e.preventDefault(); // Prevenir la acción predeterminada
                                                }
                                            }}
                                        >
                                            Ver video
                                        </a>
                                    ) : null
                                ) : null}
                            </div>


                            

                        </div>
                    ))}
                </div>
            </div>

        </div> 
        :null
        }








        {/* zona cargando */}
        {cargando && (
            <div className="overlay">
            <div className="loader"></div>
            <p className="loadingText">Completando proceso, por favor espere...</p>
            </div>
        )}

        

    </div>
  )
}



export default AnaliticaPatrocinadores;
import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation,  useParams, useNavigate, Link} from 'react-router-dom';
import axios from "axios";
import ContenedorFiltros from "./contenedorFiltros";

function Home() {

    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {username, pagina, tipoBusqueda, cualFiltro, contenido} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal]= useState(null); // Variables localstorage
    const [avatar, setAvatar] = useState("");
    const [videosMostrar, setVideosMostrar] = useState(null);
    const [cargando, setCargando] = useState(true);
    const [siguientePagina, setSiguientePagina] = useState(null);
    const [paginaAnterior, setPaginaAnterior] = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
    const [equipos, setEquipos]     = useState([]);
    const [estadios, setEstadios]   = useState([]);
    const [torneos, setTorneos]     = useState([]);
    const [busquedaMostrada, setBusquedaMostrada] = useState(null);

    let urlVolver = "/cliente/home/" + routeParams.username + "/NA/NA/NA/1";
    
    
    useEffect(() => {

        async function obtenerDatos() {
            setCargando(true);
    
            // Array de funciones a ejecutar
            const funciones = [
                async function obtenerVideos() {
                    const response = await axios.post('/videos-home-usuario', {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        params: { pagina, tipoBusqueda, cualFiltro, contenido }
                    });
                    if (response.data.status === 200) {
                        setVideosMostrar(response.data.videos);
                        setSiguientePagina(response.data.siguientePagina);
                        setPaginaAnterior(response.data.paginaAnterior);
                        setCantidadDePaginas(response.data.cantidadDePaginas);
                    }
                    else{
                        navigate(urlVolver);
                        alert(response.data.mensaje);
                    }
                },
    
                async function obtenerEquipos() {
                    const response = await axios.post("/obtener-equipo-para-editar");
                    setEquipos(response.data.equipos);
                },
    
                async function obtenerEstadios() {
                    const response = await axios.post("/obtener-estadios-para-editar");
                    setEstadios(response.data.estadios);
                },
    
                async function obtenerTorneos() {
                    const response = await axios.post("/obtener-torneos", {
                        params: { deporte: "futbol" }
                    });
                    if (response.status === 200) {
                        setTorneos(response.data.torneos);
                    }
                },

                async function obtenerBusquedaMostrada(){
                    const response = await axios.post("/obtener-busqueda-mostrada", {
                        params: { tipoBusqueda: tipoBusqueda, cualFiltro: cualFiltro, contenido: contenido }
                    });
                    if (response.data.status === 200) {
                        setBusquedaMostrada(response.data.busqueda);
                    }
                }
            ];
    
            // Ejecuta todas las funciones en paralelo y espera a que todas terminen
            try {
                await Promise.all(funciones.map(funcion => funcion()));
            } catch (error) {
                console.error('Error al obtener datos:', error);
            } finally {
                setCargando(false); // Cambia a false solo cuando todas las funciones terminan
            }
        }
    
        obtenerDatos(); // Ejecuta la función al montar el componente





        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado); setAvatar(usuarioEncontrado.usuario.avatar);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    

    

    async function atras(){
        
        var urlAver = "/cliente/home/" + routeParams.username + "/" + tipoBusqueda + "/" + cualFiltro + "/" + contenido + "/" + paginaAnterior;
        navigate(urlAver);
        window.location.reload();
    }

    async function adelante(){

        let urlAver = "/cliente/home/" + routeParams.username + "/" + tipoBusqueda + "/" + cualFiltro + "/" + contenido + "/" + siguientePagina;
        navigate(urlAver);
        window.location.reload();
    }


    function handleVideoClick(datos){ 
        const {idVideo, nombreEquipoUno, nombreEquipoDos} = datos;
        let equipoUnoURL = nombreEquipoUno.toLowerCase().replace(/\s+/g, '-');
        let equipoDosURL = nombreEquipoDos.toLowerCase().replace(/\s+/g, '-');

        let urlFinal = "/cliente/video/" + idVideo + "/" + equipoUnoURL + "-vs-" + equipoDosURL;
        // Navegar y forzar la recarga
        navigate(urlFinal, { replace: true });
        window.location.reload();  // Esto forzará la recarga completa de la página
    };

    async function ejectuarBusqueda(datos){
        console.log("Datos recibidos: ", datos);
        let tipoDeBusqueda   = datos.tipo;      // búsqueda o filtro
        let busquedaForm = datos.busqueda       // Lo que el usuario escribe en el input
        .toLowerCase()                          // Convertir a minúsculas
        .trim()                                 // Eliminar espacios al inicio y al final
        .replace(/\s+/g, '-');                  // Reemplazar espacios intermedios por guiones
        let cualFiltroForm   = datos.cualFiltro;      // cual filtor selecciona el usuario
        let contenidoFiltroF = datos.contenidoFiltro; // contenido del filtro seleccionado
        


        if(tipoDeBusqueda==="busqueda"){
            let urlAver = "/cliente/home/" + routeParams.username + "/busqueda/NA/" + busquedaForm + "/1";
            navigate(urlAver);
            window.location.reload();
        }
        else if(tipoDeBusqueda==="filtro"){
            let urlAver = "/cliente/home/" + routeParams.username + "/filtro/" + cualFiltroForm + "/" + contenidoFiltroF +  "/1";
            navigate(urlAver);
            window.location.reload();
        }
    }

   
    
    return(
        <div className=''>
            <div className='d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="tituloPaginas h2">Videos</h1>

                        {/* Contenedor para búsqueda y filtro */}
                        <ContenedorFiltros urlVolver={urlVolver} usuario={username} equipos={equipos} estadios={estadios} torneos={torneos} cualBusqueda={ejectuarBusqueda} />


                        {/* Avatar y saludo */}
                        <div className="d-flex align-items-center">
                            <h4 className="textoHomeUsuario mx-2">Hola {(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: null}</h4>
                            <img className="imgHomeUsuario" src={(infoUsuarioLocal) ? infoUsuarioLocal.usuario.avatar: null} alt="" />
                        </div>
                    </div>


                   
                   
                    
                    {/* Contenedor contenido de búsqueda */}
                    {(busquedaMostrada || tipoBusqueda!== "NA")
                    ? <div className="d-flex justify-content-center align-items-center" style={{ padding: '10px' }}>
                            <div style={{
                                backgroundColor: '#f0f0f0', // Color de fondo
                                borderRadius: '20px', // Esquinas redondeadas
                                padding: '8px 15px', // Espaciado interno
                                display: 'flex', // Para alinear items
                                alignItems: 'center' // Centrar verticalmente
                            }}>
                                <span style={{ margin: 0 }}>Búsqueda: {busquedaMostrada}</span>
                                <button
                                    onClick={() => {
                                        setBusquedaMostrada(null);
                                        let urlAver = "/cliente/home/" + routeParams.username + "/NA/NA/NA/1";
                                        navigate(urlAver);
                                        window.location.reload();
                                    }}
                                    style={{
                                        marginLeft: '10px', // Espacio entre el texto y la "X"
                                        background: 'none',
                                        border: 'none',
                                        color: 'red',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    }}
                                    aria-label="Borrar búsqueda"
                                >
                                    &times; {/* Representa una "X" */}
                                </button>
                            </div>
                    </div>
                    : null
                    }






                   


                    {/* Videos */}
                    <div className="container mt-5">
                        <div id="contenedorVideos" className="row">
                            {videosMostrar ? (
                            videosMostrar.map((videoActual, index) =>
                                videoActual.urlVideo !== "NA" ? (
                                <div key={index} className="col-md-3 mb-5 d-flex flex-column align-items-center" >
                                    
                                    <div className="contenedor-video-grabacion position-relative" onClick={(event) => {
                                            const datosAdicionales = {
                                            idVideo: videoActual.video.idVideo,
                                            nombreEquipoUno: videoActual.nombreEquipoUno,
                                            nombreEquipoDos: videoActual.nombreEquipoDos,
                                            };
                                            handleVideoClick(datosAdicionales);
                                        }}>
                                        {/* Envolver el video en un Link para redirigir */}
                                        <video
                                        id={`video-${index}`}
                                        className="video-js vjs-default-skin videoEnvVerVideos"
                                        preload="auto"
                                        poster={(videoActual.video.urlImagen) ? videoActual.video.urlImagen : ""}
                                        data-setup="{ddd}"
                                        style={{ width: '100%' }}
                                        >
                                        <source
                                            src={`${videoActual.video.urlVideo}?v=${new Date().getTime()}`}
                                            type="video/MP4"
                                        />
                                        </video>
                                        
                                        {/* Ícono de Play superpuesto */}
                                        <div className="overlay-play-icon">
                                            <i className="bi bi-play-circle iconoReproducir" ></i>
                                        </div>
                                    </div>

                                    {/* Descripción del video */}
                                    <div className="d-flex justify-content-center align-items-center my-2">
                                        <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoActual.video.equipo}</strong></p>
                                        <p className="textosDescripcionUno text-center me-2 mb-0">{videoActual.video.tipo}</p>
                                        
                                        <div className="d-flex align-items-center me-2">
                                            <i className="textosDescripcionUno bi bi-heart me-1"></i>
                                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.meGusta.length - 1}</p>
                                        </div>
                                        
                                        <div className="d-flex align-items-center me-2">
                                            <i className="textosDescripcionUno bi bi-play me-1"></i>
                                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.vistas}</p>
                                        </div>
                                    </div>
                                    {/* Descripción dos del video */}
                                    <p className="textosDescripcionDos text-center me-2 mb-0">{videoActual.nombreEquipoUno + " vs " + videoActual.nombreEquipoDos}</p>
                                    <p className="textosDescripcionDos text-center me-2 mb-0">{videoActual.torneo}</p>
                                    <p className="textosDescripcionDos text-center me-2 mb-0">{videoActual.estadio}</p>

                                </div>
                                ) : null
                            )
                            ) : (
                            <p className="text-center my-5">Buscando videos.</p>
                            )}
                        </div>
                    </div>


                    {/* Botones paginación */}
                    <div className="row">
                        <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                        <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
                    </div>
                </div>


                {/* zona cargando */}
                {cargando && (
                    <div className="overlay">
                    <div className="loader"></div>
                    <p className="loadingText">Buscando video, por favor espere...</p>
                    </div>
                )}
           

            </div>
        </div>
    )
}



export default Home;
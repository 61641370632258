import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation,  useParams, useNavigate, Link} from 'react-router-dom';
import axios from "axios";
import ContenedorVideoIndividual from "../contenedorVideoIndividual";


function VideoIndividualGlobal() {

    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {idVideo, nombrePartido} = routeParams;

    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const [avatar, setAvatar] = useState("");
    const [videoMostrar, setVideoMostrar] = useState(null);
    const [cargando, setCargando] = useState(true);
    const [patrocinador, setPatrocinador] = useState(null);
    
  // Acceder a la URL anterior desde localStorage
  const previousUrl = localStorage.getItem('urlAnterior');
  const currentUrl = localStorage.getItem('urlActual');
//   console.log( "previus: ", previousUrl);
//   console.log("current: ", currentUrl);
 
    
    
    useEffect(() => {

        async function obtenerVideoIndividual(){
            try {
                await axios.post('/obtener-video-individual', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: { idVideo: idVideo  }
                })
                .then(async function(response){
                    setCargando(false);
                    if(response.data.status===200){ setVideoMostrar(response.data.video); setPatrocinador(response.data.patrocinador); }
                    else{
                        navigate("/");
                        window.location.reload();
                        alert(response.data.mensaje);
                    }
                });
            } 
            catch (error) { console.error('Error uploading video', error); }
        }
        obtenerVideoIndividual();

        

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
 

    return(
        <div className=''>

            <div className='d-flex flex-row '>
                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Video Contenedor*/}
                    {(videoMostrar)
                    ?   <ContenedorVideoIndividual urlVolver={(previousUrl) ? previousUrl : false } idCLV={-1} idVideo={idVideo} videoMostrar={videoMostrar} patrocinador={ (patrocinador && patrocinador.videos[0]) ? patrocinador.videos[0] : ""} />
                    : <p className="text-center my-5">Buscando video</p>
                    }  

                    {/*  contenedor banner patrocinador */}
                    {(patrocinador && patrocinador.banners[0].urlBanner !== "") ?
                        <div>
                            <a href={patrocinador.banners[0].urlRedireccion} target="_blank" rel="noopener noreferrer">
                                <img className="mb-5" style={{ width: "100%" }} src={patrocinador.banners[0].urlBanner} alt="Banner Patrocinador" />
                            </a>
                        </div>
                    : null
                    }
                </div>
            </div>


            

            {/* zona cargando */}
            {cargando && (
                <div className="overlay">
                <div className="loader"></div>
                <p className="loadingText">Buscando video, por favor espere...</p>
                </div>
            )}

        </div>
    )
}



export default VideoIndividualGlobal;
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import '../estilos/partials.css';
function SidebarAdmin(){

    const paramsUsuario = useParams(); // obtenemos params para poner en url
    const navigate = useNavigate();


    function handleLogOut(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        localStorage.clear(); // limpiamos el almacentaiento local.
        axios.post("/logout")
        .then(function(response){
            console.log(response);
            navigate("/");
        });
    }


    return(
        <div id='miSidebar' className="d-flex flex-column flex-shrink-0" style={{width: "4.5rem"}}>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
                <li className="nav-item">
                    <a href={"/admin/home/" + paramsUsuario.username} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" aria-current="page" title="Home" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-house" style={{fontSize: "25px", color: "white"}}></i>
                    </a>
                </li>
                <li>
                    <a href={"/admin/crear-equipo/" +paramsUsuario.username + "/1" } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Crear equipo" data-bs-toggle="tooltip" data-bs-placement="right">
                    <img alt="" src="https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/iconos/team.svg" style={{width: "25px", filter: "invert(100%)"}}></img>
                    </a>
                </li>
                <li>
                    <a href={"/admin/crear-estadio/" + paramsUsuario.username + "/1" } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Crar estadio" data-bs-toggle="tooltip" data-bs-placement="right">
                    <img alt="" src="https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/iconos/estadio.svg" style={{width: "25px", filter: "invert(100%)"}}></img>
                    </a>
                </li> 
                <li>
                    <a href={"/admin/crear-torneo/" + paramsUsuario.username + "/1" } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Crear torneo" data-bs-toggle="tooltip" data-bs-placement="right">
                    <img alt="" src="https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/iconos/torneo.svg" style={{width: "25px", filter: "invert(100%)"}}></img>
                    </a>
                </li>
                <li>
                    <a href={"/admin/crear-patrocinador/" + paramsUsuario.username + "/1" } className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Patrocinadores" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-bank2" style={{fontSize: "25px", color: "white"}}></i>
                    </a>
                </li>
                <li>
                    <a href={"/ver-analitica/" } target="_blank" rel="noreferrer" className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Ver analítica" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-graph-up" style={{fontSize: "25px", color: "white"}}></i>
                    </a>
                </li>
            </ul>



            <div className="dropdown border-top">
            <a href="/" className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="/imagenes/marca/favicon.ico" alt="mdo" width="24" height="24" className="rounded-circle" />
            </a>
            <ul className="dropdown-menu text-small shadow">
                <li><a className="dropdown-item" href={"/admin/mi-cuenta/" + paramsUsuario.username }>Mi cuenta</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li>
                    <form>
                        <button onClick={handleLogOut} className="btn btn-light" type="submit">Cerrar Sesión</button>
                    </form>
                </li>
            </ul>
            </div>
        </div>
    )
}

export default SidebarAdmin;
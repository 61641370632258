import React, {useState} from "react";



function ContenedorFiltros({urlVolver, usuario,  equipos, estadios, torneos, cualBusqueda}) {


    // console.log("equipos: ", equipos); // equipos.nombre
    // console.log("estadios:", estadios); // estadios.nombre
    // console.log("torneos: ", torneos); // torneos.nombre


    const [mostrarFiltroPrincipal, setMostrarFiltroPrincipal] = useState(true);
   
    const [input, setInput] = useState({
        busqueda: "",
        cualFiltro: "",
        contenidoFiltro: ""
    });

    function handleChange(event) {
        const { name, value } = event.target;

        // Permite letras, números y espacios
        const filteredValue = value.replace(/[^A-Za-z0-9\s]/g, '');

        setInput(prevInput => ({ ...prevInput, [name]: filteredValue }));
    }

    function buscar(event) {
        // Lógica de búsqueda
        cualBusqueda({ ...input, tipo: "busqueda" });
    }

    function filtrar(event) {
        // Lógica de filtrado
       
        cualBusqueda({ ...input, tipo: "filtro" });
    }

    

    return (
        <div>
            <div className="d-flex align-items-center">


                {/* Búsqueda */}
                <form className="d-flex me-2">
                    <div className="form-floating me-2 inputBusquedaHome">
                        <input 
                            onChange={handleChange}
                            value={input.busqueda}
                            name="busqueda"
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="buscar"
                            pattern="[A-Za-z0-9\s]*"
                        />
                        <label htmlFor="floatingInput">Buscar</label>
                    </div>
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={(event) => {
                            event.preventDefault();
                            const datosAdicionales = {
                                tipo: "busqueda",
                                busqueda: input.busqueda,
                                deporteSeleccionado: ""
                            };
                            buscar(datosAdicionales);
                        }}
                    >
                        <i className="bi bi-search"></i>
                    </button>
                </form>





                {/* Filtro */}
<form className="d-flex">
    {mostrarFiltroPrincipal ? (
        <div className="form-floating me-2 inputFiltroHome">
            <select
                value={input.cualFiltro}
                name="cualFiltro"
                className="form-select"
                id="floatingFiltro"
                onChange={(event) => {
                    const filtroSeleccionado = event.target.value;
                    setInput({ ...input, cualFiltro: filtroSeleccionado });

                    if (["equipo", "torneo", "estadio"].includes(filtroSeleccionado)) {
                        setMostrarFiltroPrincipal(false); // Oculta el dropdown principal
                    }
                }}
            >
                <option value="">Filtrar</option>
                <option value="equipo">Equipo</option>
                <option value="torneo">Torneo</option>
                <option value="estadio">Estadio</option>
            </select>
            <label htmlFor="floatingFiltro">Filtrar</label>
        </div>
    ) : (
        <div className="form-floating me-2 inputFiltroHome">
            <select
                value={input.contenidoFiltro}
                name="contenidoFiltro"
                className="form-select"
                id="floatingContenidoFiltro"
                onChange={(event) => {
                    const selectedId = event.target.value;
                    setInput({
                        ...input,
                        contenidoFiltro: selectedId,
                    });
                    setMostrarFiltroPrincipal(true); // Opcional: volver al dropdown principal después de seleccionar
                }}
            >
                <option value="">Selecciona una opción</option>
                {/* Mostrar las opciones de acuerdo al filtro seleccionado */}
                {(input.cualFiltro === "equipo" ? equipos : 
                  input.cualFiltro === "torneo" ? torneos : 
                  input.cualFiltro === "estadio" ? estadios : []
                ).map((item, index) => (
                    <option 
                        key={index} 
                        value={
                            input.cualFiltro === "equipo" ? item.idEquipo :
                            input.cualFiltro === "torneo" ? item.idTorneo :
                            item.idEstadio
                        }
                    >
                        {item.nombre}
                    </option>
                ))}
            </select>
            <label htmlFor="floatingContenidoFiltro">
                {input.cualFiltro === "equipo"
                    ? "Equipo"
                    : input.cualFiltro === "torneo"
                    ? "Torneo"
                    : "Estadio"}
            </label>
        </div>
    )}

    <button
        onClick={(event) => {
            event.preventDefault();
            filtrar(input);
        }}
        type="submit"
        className="btn btn-primary"
    >
        <i className="bi bi-funnel-fill"></i>
    </button>
</form>






            </div>       
        </div>
    );
}





export default ContenedorFiltros;
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function BotonMensajeGrabar(props) {

    const navigate = useNavigate();

    const { idVideo, idCLV } = props; // contiene la url para volver: /cliente/mi-cuenta/eduardo2
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    

    const[inputDos, setInputDos] = useState ({
        descripcion: "",
    });
    
    function handleChange2(event) {
        const { name, value } = event.target;

        setInputDos(prevInputDos => {
            return {
                ...prevInputDos,
                [name]: value
            };
        });

    }


    async function solicitarGrabacion(event){
        event.preventDefault();

        let texto = inputDos.descripcion;
        
        if(texto===""){   alert("debes ingresar un texto describiendo la jugada."); }
        else{
            // Enviamos a subir la imagen
            await axios.post("/solicitar-grabacion", {
                params: {  idCLV: idCLV, idVideo: idVideo, texto: texto }
            })
            .then(async function(response){
                console.log(response);
                if(response.data.status===200){
                    window.location.reload();  alert("Solicitud enviada correctamente. Por favor espera a nuestra respuesta.");
                }
                else{  window.location.reload();  alert(response.data.mensaje); }
            });
        }
    }


    

    return(
        <div>
            <Button  variant="primary w-100 mt-1" onClick={handleShow}>
                Solicitar Narración
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Solicitar Narración</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>

                    <p>Si deseas que narremos tu jugada, por favor describe la jugada <strong>lo más descriptiva posible.</strong>, añadiendo nombres de jugadores por ejemplo.</p>

                    <form>
                        {/* contraseña actual */}
                        <div className="form-floating my-3">
                            <textarea onChange={handleChange2} name="descripcion"  className="form-control" placeholder="descripción" id="floatingTextarea2" style={{width: "100%", height: "200px"}}></textarea>
                            <label htmlFor="floatingTextarea2">Descripción</label>
                        </div>

                        <button type="submit" className="btn btn-primary w-100" onClick={solicitarGrabacion} >Enviar solicitud</button>

                    </form>

                    <p className="mt-3"><strong>Importante: </strong>Revisaremos tu solicitud y una vez aceptada, te notificaremos por correo para que puedas verlo. <i>Solo envía la solicitud una vez. </i> </p>
                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BotonMensajeGrabar;
import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import SeleccionarDispositivo from "../camarografo/seleccionarDispositivo";


function MisPartidosGrabar() {
    
    const location = useLocation();
    const navigate = useNavigate();
    const routeParams = useParams();

    let username = routeParams.username;
    let urlVolver = "/cliente/partidos/" + routeParams.username;
    const [infoUsuarioLocal, setInfoLocal]= useState(null);
    const [avatar, setAvatar] = useState("");
    const [partidos, setPartidos] = useState(null);
    const [camaras, setCamaras]                             = useState([]);
    const [microfonos, setMicrofonos]                       = useState([]);
    const [camaraSeleccionada, setCamaraSeleccionada]       = useState(null);
    const [microfonoSeleccionado, setMicrofonoSeleccionado] = useState(null);
    const [camaraDefault, setCamaraDefault]                 = useState(null);
    const [microfonoDefault, setMicrofonoDefault]           = useState(null);
    const [esPC, setEsPc]                                   = useState(null);
    const [cargando, setCargando]                           = useState(false);
    
    
    useEffect(() => {
        async function ObtenerMisPartidos(){
            setCargando(true);
            await axios.post("/obtener-mis-partidos-pagos", {
                params: { username: username }
            })
            .then(async function(response){
                setCargando(false);
                if(response.status===200){ 
                    setPartidos(response.data.partidosSinEditar);
                }
            });
        }
        ObtenerMisPartidos();

        async function esPC(){
            const esMovil = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            const esPC = !esMovil;
            

            console.log("dispositivo identificado como pc: ", esPC);
            console.log("imprimir agent: ", navigator.userAgent);

            // Si es pc:
            if(esPC===true){
                setEsPc(true);

                // Obtener los dispositivos de entrada de video y audio disponibles
                const dispositivos = await navigator.mediaDevices.enumerateDevices();
                const camaras = dispositivos.filter(dispositivo => dispositivo.kind === 'videoinput');
                const microfonos = dispositivos.filter(dispositivo => dispositivo.kind === 'audioinput');
                setCamaraDefault(camaras[0].deviceId);
                setMicrofonoDefault(microfonos[0].deviceId);
                setCamaras( camaras);
                setMicrofonos(microfonos);
            } 
            else{setEsPc(false);}
        }
        esPC();



        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado); setAvatar(usuarioEncontrado.usuario.avatar);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
    
    function handleSeleccionarDispositivo(datos){
        // guardamos los dispositivos seleccionados
        setCamaraSeleccionada(datos.camaraSeleccionada);
        setMicrofonoSeleccionado(datos.microfonoSeleccionado);
    }


    async function grabarVideo(datos) {
        let nombreEquipoUno = datos.nombreEquipoUno.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        let nombreEquipoDos = datos.nombreEquipoDos.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        let estadio = datos.estadio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        let torneo = datos.torneo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '-');
        
        const isAndroid         = /Android/i.test(navigator.userAgent);
        const minRAM            = 8; // Mínimo de 8 GB de RAM, con 11 excelente
        const minAndroidVersion = 10; // Mínimo Android 11
        // const minUploadSpeed    = 12; // 12 Mbps de subida (valor estimado)
        // const maxLatency        = 150; // 150 ms de latencia. Entre más baja mejor.    

        if (isAndroid) {
            // Calculo de RAM
            const deviceRAM = navigator.deviceMemory || 4; // Suponiendo 4 GB por defecto si no está disponible
            
            // Función para obtener la versión de Android
            const getAndroidVersion = () => {
                const userAgent = navigator.userAgent;
                const match = userAgent.match(/Android\s([0-9\.]*)/);
                return match ? parseFloat(match[1]) : 0;
            };
            const androidVersion = getAndroidVersion(); // Obtener la versión de Android
            console.log("Versión de Android: ", androidVersion);
            console.log("ram: ", deviceRAM);
            
            // Verificación de RAM
            if (deviceRAM < minRAM) {
                console.log("Dispositivo con RAM insuficiente: ", deviceRAM);
                return navigate("/alerta", {
                    state: {
                        titulo: "No puedes grabar",
                        mensaje: "Tu dispositivo no cuenta con la suficiente RAM. Por favor intenta con otro dispositivo con RAM mayor o igual a 8 GB. Tu RAM es: " + deviceRAM + " GB.",
                        urlMensaje: urlVolver,
                        nombreBoton: "Volver"
                    }
                });
            }
            // Verificación de versión de Android
            if (androidVersion < minAndroidVersion) {
                console.log("Versión de Android insuficiente: ", androidVersion);
                return navigate("/alerta", {
                    state: {
                        titulo: "No puedes grabar",
                        mensaje: "Tu dispositivo no cuenta con una versión de Android reciente. Por favor intenta con otro dispositivo. Tu versión es: " + androidVersion,
                        urlMensaje: urlVolver,
                        nombreBoton: "Volver"
                    }
                });
            }
        }
    
        // Si pasa todas las verificaciones, o si no es Android, proceder con la grabación
        let urlGrabar = "/grabando-ios/" + username + "/desdeCliente/" + datos.idPartido + "-" + nombreEquipoUno + "-" + nombreEquipoDos + "-" + (datos.dia) + "-" + (datos.mes) + "-" + (datos.año) + "-" + (estadio) + "-" + (torneo);
        navigate("/alerta-datos", {
            state: {
                titulo: "Voltea tu celular y prueba tu internet",
                mensaje: "Necesitas tener un internet óptimo para grabar. Prueba que la velocidad de carga sea mayor a 12 Mbps. Haz click para probar. Si ya estás seguro, haz click en grabar ahora.",
                nombreBotonUno: "Test velocidad",
                nombreBotonDos: "Grabar ahora",
                urlBotonUno: "https://www.speedtest.net/es",
                urlBotonDos: urlGrabar,
                mostrarBotonUno: true,
                mostrarBotonDos: true,
                urlAfueraDeWeb: true, // si la url es afuera de la web, para usr target blank
                datosPagina: {
                    esPC: esPC,
                    datos: datos,
                    camaraSeleccionada: camaraSeleccionada,
                    microfonoSeleccionado: microfonoSeleccionado,
                    camaraDefault: camaraDefault,
                    microfonoDefault: microfonoDefault,
                    urlGrabar: urlGrabar
                }
            }
        });
    }
   
    async function videosPartido(datos){
        let urlAver = "/cliente/editar-videos/" + routeParams.username + "/1";
        navigate(urlAver, {state: {datos: datos} });
    }

   

    return(
        <div className=''>
            <div className='d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />

 
                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Grabar</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                            {/* Seleccionar dispositivo */}
                            {(camaras.length>1)
                            ?<SeleccionarDispositivo camaras={camaras} microfonos={microfonos} onSeleccionarDispositivo={handleSeleccionarDispositivo} />
                            :null
                            }
                            </div>
                        </div>
                    </div>



                    {/* Primer contnedor*/}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <div className="container-fluid py-5">
                            <h2 className="display-5 fw-bold">¡Graba tus partidos aquí!</h2>
                            <p className="col-md-8 fs-4">Si dedeas grabar un partido para que se publique en Cómo la vio, selecciona grabar en alguno de tus partidos disponibles.</p>
                        </div>
                    </div>


                    {(partidos)
                    ?<div  className="p-5 mb-4 rounded-3 bg-body-tertiary">
                        <h2 className=''>Partidos por Grabar</h2>
                        <p className=''>Aquí podrás ver todos los partidos que están pendientes por grabar</p>
                        
                        {/* El total de columnas es: largo del arreglo / 4 o 12 /3, 4 */}
                        <div className="row">
                            {partidos.map((partidoActual, index) => ( 
                                <div className="col-sm-4 col-lg-4" key={index}> 
                                    <div className="card m-1">
                                        <div className="card-header text-light" style={{backgroundColor: "#0E1465"}}>id: {partidoActual._doc.idPartido}</div>
                                        <div className="d-flex justify-content-between align-items-center p-2">
                                            <img src={partidoActual.imagenEquipoUno} alt="" className="card-img-top imagenEquipos" />
                                            <span className="">vs</span>
                                            <img src={partidoActual.imagenEquipoDos} alt="" className="card-img-top imagenEquipos" />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{partidoActual.nombreEquipoUno} vs {partidoActual.nombreEquipoDos}</h5>
                                            <p className="card-text"><strong>Deporte:</strong> {partidoActual._doc.deporte}<br/> <strong>id Pago:</strong> {partidoActual.idPago}</p>    
                                            

                                            <button
                                            className="w-100 btn btn-primary"
                                            id={partidoActual.idPartido}
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                idPartido: partidoActual._doc.idPartido,
                                                idEquipoUno: partidoActual._doc.idEquipoUno,
                                                idEquipoDos: partidoActual._doc.idEquipoDos,
                                                nombreEquipoUno: partidoActual.nombreEquipoUno,
                                                nombreEquipoDos: partidoActual.nombreEquipoDos,
                                                dia: partidoActual._doc.fechaHora.dia,
                                                mes: partidoActual._doc.fechaHora.mes,
                                                año: partidoActual._doc.fechaHora.año,
                                                estadio: partidoActual.estadio,
                                                torneo: partidoActual.torneo
                                                };
                                                grabarVideo(datosAdicionales);
                                            }}
                                            >
                                            Grabar Video
                                            </button>  

                                            <button
                                            className="w-100 mt-1 btn btn-secondary"
                                            id={partidoActual.idPartido}
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                idPartido: partidoActual._doc.idPartido,
                                                idEquipoUno: partidoActual._doc.idEquipoUno,
                                                idEquipoDos: partidoActual._doc.idEquipoDos,
                                                nombreEquipoUno: partidoActual.nombreEquipoUno,
                                                nombreEquipoDos: partidoActual.nombreEquipoDos,
                                                imagenEquipoUno: partidoActual.imagenEquipoUno,
                                                imagenEquipoDos: partidoActual.imagenEquipoDos,
                                                dia: partidoActual._doc.fechaHora.dia,
                                                mes: partidoActual._doc.fechaHora.mes,
                                                año: partidoActual._doc.fechaHora.año,
                                                estadio: partidoActual.estadio,
                                                torneo: partidoActual.torneo
                                                };
                                                videosPartido(datosAdicionales);
                                            }}
                                            >
                                            Editar videos
                                            </button> 

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :<p>En proceso</p>
                    }


                    {/* zona cargando */}
                    {cargando && (
                        <div className="overlay">
                        <div className="loader"></div>
                        <p className="loadingText">Subiendo video, por favor espere...</p>
                        </div>
                    )}



                    

                    
                </div>
            </div>
        </div>
    )
}



export default MisPartidosGrabar;
import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation,  useParams, useNavigate, Link} from 'react-router-dom';
import axios from "axios";
import ContenedorVideoIndividual from "../contenedorVideoIndividual";

function VideoIndividual() {

    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {idvideo, nombrePartido} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal]= useState(null); // Variables localstorage
    const [avatar, setAvatar] = useState("");
    const [videoMostrar, setVideoMostrar] = useState(null);
    const [cargando, setCargando] = useState(true);
    const [patrocinador, setPatrocinador] = useState(null);
  
    
    
    useEffect(() => {

        async function obtenerVideoIndividual(){
            try {
                await axios.post('/obtener-video-individual', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: { idVideo: idvideo  }
                })
                .then(async function(response){
                    setCargando(false);
                    if(response.status===200){ setVideoMostrar(response.data.video); setPatrocinador(response.data.patrocinador);  }
                });
            } 
            catch (error) { console.error('Error uploading video', error); }
        }
        obtenerVideoIndividual();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado); setAvatar(usuarioEncontrado.usuario.avatar);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ 
            navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión o regístrate.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );
        }
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
    
    // Acceder a la URL anterior desde localStorage
    const previousUrl = localStorage.getItem('urlAnterior');
    const currentUrl = localStorage.getItem('urlActual');
    // console.log( "previus: ", previousUrl);
    // console.log("current: ", currentUrl);


    return(
        <div className=''>
        {(infoUsuarioLocal)
        ? <div className='d-flex flex-row '>

            {/* BARRA LATERAL */}
            <SidebarUsuario />


            {/* Columna 2 */}
            <div className="flex-grow-1 container-fluid">
                
                {/* Contenedor título  */}
                <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                    <h1 className="h2">{ (videoMostrar)?  videoMostrar.video.equipo + " - " + videoMostrar.video.tipo : "" }</h1>

                    {/* Avatar y saludo */}
                    <div className="d-flex align-items-center">
                    <h4 className="textoHomeUsuario mx-2">Hola {(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: null}</h4>
                    <img className="imgHomeUsuario" src={(infoUsuarioLocal) ? infoUsuarioLocal.usuario.avatar: null} alt="" />
                    </div>
                </div>


                {/* Videos */}
                {/* Video Contenedor*/}
                {(videoMostrar)
                ?   <ContenedorVideoIndividual  urlVolver={(previousUrl) ? previousUrl : false } idCLV={(infoUsuarioLocal) ? infoUsuarioLocal.usuario.idCLV: -1} idVideo={idvideo} videoMostrar={videoMostrar}  patrocinador={ (patrocinador && patrocinador.videos[0]) ? patrocinador.videos[0] : ""}  />
                : <p className="text-center my-5">Buscando video</p>
                }   


                {/*  contenedor banner patrocinador */}
                    {(patrocinador && patrocinador.banners[0].urlBanner !== "") ?
                        <div>
                            <a href={patrocinador.banners[0].urlRedireccion} target="_blank" rel="noopener noreferrer">
                                <img className="mb-5" style={{ width: "100%" }} src={patrocinador.banners[0].urlBanner} alt="Banner Patrocinador" />
                            </a>
                        </div>
                    : null
                    }  
            </div>



            {/* zona cargando */}
            {cargando && (
                <div className="overlay">
                <div className="loader"></div>
                <p className="loadingText">Buscando video, por favor espere...</p>
                </div>
            )}
            </div>
        : <p>Buscando usuario</p>
        }
            
        </div>
    )
}



export default VideoIndividual;
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function Etiquetas(props) {

    const navigate = useNavigate();

    const { idVideo, etiquetasActuales } = props; // contiene la url para volver: /cliente/mi-cuenta/eduardo2
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    

    const[inputDos, setInputDos] = useState ({
        etiquetas: "",
    });
    
    function handleChange2(event) {
        const { name, value } = event.target;

        // Expresión regular para permitir solo letras y números, sin espacios
        const validInputRegex = /^[a-zA-Z\s,]*$/;

        // Validar el valor antes de actualizar el estado
        if (validInputRegex.test(value)) {
            setInputDos(prevInputDos => {
                return {
                    ...prevInputDos,
                    [name]: value
                };
            });
        }
    }


    async function anadirEtiquetas(event){
        event.preventDefault();
        
        // Enviamos a subir la imagen
        await axios.post("/anadir-etiquetas", {
            params: {  etiquetas: inputDos.etiquetas, idVideo: idVideo }
        })
        .then(async function(response){
            if(response.data.status===200){
                window.location.reload();  alert("Etiquetas añadidas correctamente.");
            }
            else{  window.location.reload();  alert("Error al añadir etiquetas"); }
        });
    }


    

    return(
        <div>
            <Button  variant="primary w-100 mt-1" onClick={handleShow}>
                Etiquetas
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Etiquetas</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>

                    <h5 className="mb-3">Etiquetas actuales</h5>
                    {etiquetasActuales ? (
                    <div>
                        <ul className="list-group">
                        {etiquetasActuales.map((actual, index) => (
                            <li key={index} className="list-group-item">
                            {actual.nombreEtiqueta}
                            </li>
                        ))}
                        </ul>
                    </div>
                    ) : null}



                    <h5 className="mt-5 mb-2">Añade etiquetas</h5>
                    <p>Añade las etiquetas para este video, <strong>separadas por comas</strong> .</p>
                    <form>
                        {/* contraseña actual */}
                        <div className="form-floating my-3">
                            <input onChange={handleChange2}  name="etiquetas" type="text" className="form-control" id="floatingText" placeholder="Etiquetas" />
                            <label htmlFor="floatingText">Etiqueta1, Etiqueta2, Etiqueta3</label>
                        </div>

                        <button type="submit" className="btn btn-primary w-100" onClick={anadirEtiquetas} >Añadir etiquetas</button>

                    </form>
                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Etiquetas;
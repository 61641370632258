import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";


function TodosUsuarios(){
    const navigate    = useNavigate();
    const routeParams = useParams();
    const location    = useLocation(); 
    let {username, pagina} = routeParams; // capturamos para paginación

    const [siguientePagina, setSiguientePagina]      = useState(null);
    const [paginaAnterior, setPaginaAnterior]        = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas]  = useState(null);
    const [infoUsuarioLocal, setInfoLocal]           = useState({}); // Variables localstorage
    const [equipos, setEquipos]                      = useState([]);
    const [usuarios, setUsuarios]                    = useState([]);
    const [archivo, setArchivo]                     = useState(null); // imagen subida
    const urlVolver   = "/admin/usuarios/" + routeParams.username + "/1";
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////


        async function ObtenerUsuarios(){
            await axios.post("/obtener-usuarios", {
                params: { pagina: pagina }
            })
            .then(async function(response){
                if(response.status===200){ 
                    setUsuarios(response.data.usuarios); setSiguientePagina(response.data.siguientePagina); setPaginaAnterior(response.data.paginaAnterior); setCantidadDePaginas(response.data.cantidadDePaginas)
                }
            });
        }
        ObtenerUsuarios();

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


 
    
    async function atras(){
        let urlAver = "/admin/usuarios/" + routeParams.username + "/" + paginaAnterior;
        navigate(urlAver);
        window.location.reload();
    }

    async function adelante(){
        let urlAver = "/admin/usuarios/" + routeParams.username + "/" + siguientePagina;
        navigate(urlAver);
        window.location.reload();
    }

    async function  verUsuario(datos){
        let idUsuario = datos.idUsuario;
        let urlVerUsuario = "/admin/ver-usuario/" + routeParams.username + "/" + idUsuario;
        navigate(urlVerUsuario);
    }

    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Ver usuarios</h1>
                    </div>
            

                    {/* Equipos existentes */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <div className=" my-5">
                            <h3 style={{textAlign: "center"}}>Usuarios creados</h3>
                            <p  style={{textAlign: "center"}}>En esta sección puedes ver todos los usuarios.</p>


                            { (usuarios)
                            ? usuarios.map( (usuarioActual, key) => (
                                <div key={usuarioActual.idCLV} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                    {/* id */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.idCLV}  />
                                            <label htmlFor="floatingInputGrid">id</label>
                                        </div>
                                    </div>

                                    {/* Avatar */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <img src={usuarioActual.avatar} alt="avatar" style={{width: "80px", borderRadius: "20%"}} />
                                        </div>
                                    </div>

                                     {/* Username */}
                                     <div className="col-sm-2">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.username}  />
                                            <label htmlFor="floatingInputGrid">Username</label>
                                        </div>
                                    </div>

                                    {/* Nombre completo */}
                                    <div className="col-sm-3">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.nombre + " " + usuarioActual.apellido}  />
                                            <label htmlFor="floatingInputGrid">Nombre</label>
                                        </div>
                                    </div>

                                    {/* Correo */}
                                    <div className="col-sm-3">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.email}  />
                                            <label htmlFor="floatingInputGrid">Correo</label>
                                        </div>
                                    </div>

                                    {/* Teléfono */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuarioActual.telefono}  />
                                            <label htmlFor="floatingInputGrid">Teléfono</label>
                                        </div>
                                    </div>

                                    
                                    {/* Botón cambio de imagen */}
                                    <div className="col-sm-1">
                                        <button
                                        className="w-100 btn btn-warning"
                                        onClick={(event) => {
                                            const datosAdicionales = {
                                            idUsuario: usuarioActual.idCLV
                                            };
                                            verUsuario(datosAdicionales);
                                        }}
                                        >
                                        Ver
                                        </button>
                                    </div>
                                </div>
                            ))
                            : <p>Aún cargando</p>
                            }


                            {/* Botones paginación */}
                            <div className="row mt-5">
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TodosUsuarios;
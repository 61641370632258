import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import RegistrarParaVer from './registrarParaVer';
 

function VerTorneos() {

  const navigate    = useNavigate();
  const location    = useLocation(); // cuando no se envía nada en state, location,state es null
  const routeParams = useParams();
  let { deporte }   = routeParams; // capturamos para paginación

  const [torneos, setTorneos] = useState(null);
  const [cargando, setCargando]  = useState(true);
  
  useEffect(() => {

    async function obtenerTorneos(){
      await axios.post("/obtener-torneos", {
        params: {deporte: deporte}
      })
      .then(async function(response){
        if(response.status===200){ 
          setCargando(false);
          setTorneos(response.data.torneos);
        }
      });
    }
    obtenerTorneos();





    // Modificar la clase de body par página de registro - styles.css /////////
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  
 
    

  
  return(
    <div className='contenedorPaginasUsuario'>
        
      {/* Primer contenedor: imagen principal */}
      <div className='contenedorVERVIDEOS text-light'>
          <h1>Torneos de {deporte}</h1>
          <p className='subContenedorPrincipal'>Escoge el torneo que más te guste</p>
      </div>


      
      <div className='container contenedorTorneosGlobal' >

        <div className="row">
        {torneos ? (
        torneos.map((actual, index) => {
        // Generar la URL transformando el nombre: eliminando espacios, reemplazándolos por guiones y convirtiendo a minúsculas
        const nombreURL = actual.nombre.replace(/\s+/g, '-').toLowerCase();

        return (
          <div className="col-md-4" key={index}>
            {/* Para asegurar tres columnas en pantallas medianas y grandes */}
            <div className="card m-2">
              <div
                className="card-header text-light"
                style={{ backgroundColor: "#414525" }}
              >
                {actual.idTorneo}
              </div>
              <img
                src={actual.imagen}
                alt="Imagen desde IPFS"
                className="card-img-top"
                style={{ width: "100%", height: "auto" }}
              />
              <div className="card-body">
                <h5 className="card-title">{actual.nombre}</h5>
                {/* Enlace con el nombre transformado y en minúsculas */}
                <a
                  className="btn btn-primary w-100 mt-2"
                  href={`/ver-torneo/${nombreURL}`}
                  role="button"
                >
                  Ver torneo
                </a>
              </div>
            </div>
          </div>
        );
        })
        ) : null}
        </div>


      </div>

      <RegistrarParaVer />

      {/* zona cargando */}
      {cargando && (
          <div className="overlay">
          <div className="loader"></div>
          <p className="loadingText">Buscando torneo, por favor espere...</p>
          </div>
      )}

    </div>
  )
}



export default VerTorneos;
import React, {useState, useEffect} from "react";
import axios from "axios";
import BotonMensajeGrabar from "./botonMensajeGrabar";
import RegistrarParaVer from "./webGlobal/registrarParaVer";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function ContenedorVideoIndividual(datos) {

    const navigate = useNavigate();
    let videoMostrar = datos.videoMostrar; // reparar datos
    let patrocinador = datos.patrocinador; 
    let idVideo      = datos.idVideo;
    let idCLV        = datos.idCLV;
    let urlVolver    = datos.urlVolver; 
    const [existeMeGusta, setExisteMeGusta] = useState(null);
    const [meGustaCount, setMeGustaCount]   = useState(videoMostrar.video.meGusta.length - 1);
    const [etiquetas, setEtiquetas]         = useState(null);
    const [mostrarModal, setMostrarModal]   = useState(null);
    // modal:
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal


    const [mostrarVideoPartido, setMostrarVideoPartido] = useState(false); // Estado para controlar cuándo mostrar el video del partido
    // Función para manejar el final del video del patrocinador
    const handlePatrocinadorEnd = () => {
        setMostrarVideoPartido(true); // Al finalizar el video del patrocinador, mostramos el video del partido
    };

    useEffect(() => {
        async function buscarMeGustaUsuario() {
            await axios.post("/buscar-megusta", {
                params: { idVideo: idVideo, idCLV: idCLV}
            })
            .then(async function(response){
                if(response.data.status===200){ 
                    if(response.data.existeMegusta){setExisteMeGusta(true);}
                    else{setExisteMeGusta(false);}
                   
                }
                else{ console.log("error en el proceso");  }
            });
        }
        buscarMeGustaUsuario();

        async function obtenerEtiquetas(){
            await axios.post("/obtener-etiquetas-individual", {
                params: {idVideo: idVideo}
            })
            .then(async function(response){
                if(response.data.status ===200){
                    setEtiquetas(response.data.etiquetas);
                }
            })
        }
        obtenerEtiquetas();

    }, []);

    async function meGusta(){
        
        async function actualizarLikes(){
            await axios.post("/actualizar-likes", {
                params: { idVideo: idVideo, idCLV: idCLV}
            })
            .then(async function(response){
                if(response.data.status===200){ 
                    if(response.data.existeMeGusta === false){ // false porque no existía el me gusta, pero ahora si.
                        console.log("Me gusta añadido correctamente");
                        setExisteMeGusta(true);
                        setMeGustaCount(prev => prev + 1);  // Incrementar el contador
                    }
                    else{ console.log("este usuario ya dio me gusta a este video");  }
                }
                else{
                    console.log(response.data.mensaje);
                    alert(response.data.mensaje);
                }
            });
        }
        actualizarLikes();
    }

   //console.log(urlVolver);

    // Función para compartir
    async function compartirVideo(){

        const urlVideoCompartir = videoMostrar.video.urlVideo;
        const tituloVideo = `${videoMostrar.nombreEquipoUno} vs ${videoMostrar.nombreEquipoDos} - ${videoMostrar.torneo}`;
    
        if (navigator.share) {
        navigator.share({
            title: tituloVideo,
            text: 'Mira este video increíble!',
            url: urlVideoCompartir
        })
        .then(async () => {
            console.log('Video compartido con éxito')

            // verificar si es usuario logueado o no
            if(idCLV>=0){
                // Ejecutamos aumento de puntos
                await axios.post("/aumentar-puntos", {
                    params: { idVideo: idVideo, idCLV: idCLV}
                })
                .then(async function(response){
                    if(response.data.status===200){ 
                    console.log("puntos aumentados");
                    }
                    else{ console.log("error en el proceso");  }
                });
            }
        })
        .catch((error) => console.error('Error al compartir:', error));
        } else {
        alert('La función de compartir no está disponible en este navegador');
        }
    };


    async function meGustaRegistrar(){
        handleShow();
        return;
    }
   

    return(
       
    <div className="container my-5">
        <div id="contenedorVideos" className="row">
            <div className="contenedor-video-grabacion position-relative">

                {/* Video de patrocinador */}
                {(patrocinador!=="" && patrocinador.urlVideo!=="" && !mostrarVideoPartido) ? 
                
                <video
                className="video-js vjs-default-skin videoIndividual"
                preload="auto"
                controls
                autoPlay
                data-setup="{ddd}"
                style={{ width: '100%' }}
                onEnded={handlePatrocinadorEnd} // Ejecutar cuando el video del patrocinador termine
                >
                <source
                    src={patrocinador?.urlVideo}// primero verifica que exista para luego si proceder a su propiedad
                    type="video/MP4"
                />
                </video>
                : null
                }
                


                {/* Video de partido */}
                {(mostrarVideoPartido || !patrocinador.urlVideo ) ? 
                <video
                id={videoMostrar.video.idVideo}
                className="video-js vjs-default-skin videoIndividual"
                preload="auto"
                controls
                poster={videoMostrar.video.urlImagen}
                data-setup="{ddd}"
                style={{ width: '100%' }}
                >
                <source
                    src={videoMostrar.video.urlVideo}
                    type="video/MP4"
                />
                </video>
                :null
                }

                <RegistrarParaVer />
                
                {/* Botón compartir */}
                <button className="btn btn-success w-100 mt-3" onClick={compartirVideo}>
                Compartir Video
                </button>

                {/* Descripción del video */}
                <div className="d-flex justify-content-center align-items-center my-2">
                    <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoMostrar.video.equipo}</strong></p>
                    <p className="textosDescripcionUno text-center me-2 mb-0">{videoMostrar.video.tipo}</p>
                    {/* Me gusta */}

                    {/* Si no está loegado, el id es -1 */}
                    {(idCLV===-1)
                    ? <div className="d-flex align-items-center me-2">
                    {/* MODAAAL AQUI */}
                        <button onClick={meGustaRegistrar} className="btn p-0">
                            <i className="textosDescripcionUno bi bi-heart me-1"></i>
                        </button>
                        <p className="textosDescripcionUno text-center mb-0">{meGustaCount}</p>
                    </div>
                    : <div className="d-flex align-items-center me-2">
                    {existeMeGusta ? (
                        <i style={{ color: "#3A58A5" }} className="textosDescripcionUno bi bi-heart-fill me-1"></i>
                    ) : (
                        <button onClick={meGusta} className="btn p-0">
                        <i className="textosDescripcionUno bi bi-heart me-1"></i>
                        </button>
                    )}
                    <p className="textosDescripcionUno text-center mb-0">{meGustaCount}</p>
                    </div>
                    }

                    
                    

                    

                    
                    <div className="d-flex align-items-center me-2">
                        <i className="textosDescripcionUno bi bi-play me-1"></i>
                        <p className="textosDescripcionUno text-center mb-0">{videoMostrar.video.vistas}</p>
                    </div>
                </div>
                {/* Descripción dos del video */}
                <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.nombreEquipoUno + " vs " + videoMostrar.nombreEquipoDos}</p>
                <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.torneo}</p>
                <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.nombreEstadio}</p>

                {/* Etiquetas */}
                <p className="textosDescripcionDos text-center mt-4">Etiquetas</p>
                {(etiquetas) ? (
                    <div className="etiquetas-container">
                        {etiquetas.map((actual, key) => (
                            <p className="etiqueta-item" key={key}>{actual.nombreEtiqueta}</p>
                        ))}
                    </div>
                ) : null}


                {/* botón enviar a grabar */}
                {(idCLV===-1) ? null : <div> <BotonMensajeGrabar idCLV={idCLV} idVideo={idVideo} /></div>}


                {/* Botón atrás */}
                <a 
                className={`btn btn-warning w-100 mt-3 ${urlVolver === false ? 'disabled' : ''}`} 
                href={urlVolver !== false ? urlVolver : '#'}
                onClick={(e) => urlVolver === false && e.preventDefault()}
                >
                Atrás
                </a>

               



                {/* Modal para cuando no está logueado */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Iniciar Sesión</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Por favor, inicia sesión para poder dar "me gusta" a este video.</p>
                        <Button variant="primary w-100" onClick={() => navigate("/iniciar-sesion")}>
                            Iniciar Sesión
                        </Button>
                        <Button variant="dark w-100 mt-1" onClick={() => navigate("/registrar")} >
                            Registrar
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                       
                    </Modal.Footer>
                </Modal>


                {/* Boton de link de video patrocinador */}
                {(patrocinador && patrocinador.urlRedireccion!== "") ?
                    <a class="btn btn-primary w-100 mt-5" target="_blank" rel="noreferrer" href={patrocinador.urlRedireccion} role="button">Ver patrocinador</a>
                : null
                }
            </div>
        </div>
    </div>
    )
}


export default ContenedorVideoIndividual;
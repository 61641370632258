import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function HeaderUsuario() {
    const navigate = useNavigate();
    const paramsUsuario = useParams(); // obtenemos params para poner en url

    function handleLogOut(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        localStorage.clear(); // limpiamos el almacentaiento local.
        axios.post("/logout")
        .then(function(response){
            console.log(response);
            navigate("/");
        });
    }
 
 
    return(
        <div>
            <nav id="miNavBar" className="navbar navbar-expand-sm navbar-dark" aria-label="Third navbar example" >
                <div className="container-fluid">
                    {/* Imagen */}
                    <a className="navbar-brand" href={"/cliente/home/" + paramsUsuario.username + "NA/NA/NA/1"} ><img src="/imagenes/marca/clvhorizontalblanco.png" alt="" height="40px" /></a>

                    {/* Botón adaptable */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    
                    <div className="collapse navbar-collapse" id="navbarsExample04">
                        {/* Menú principal */}
                        <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                            <li className="nav-item"><a className="nav-link text-light" href={"/cliente/home/" + paramsUsuario.username + "/NA/NA/NA/1"}>Home</a> </li>
                            {/* Dropdown Crear */}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-light" href={"#"} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Ver videos
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">  
                                    <li><a className="dropdown-item mi-drowpdown" href={"/cliente/videos/futbol" }>Fútbol</a></li>
                                </ul> 
                            </li>
                            {/* <li id="" className="nav-item"><a className="nav-link text-light" href={"/cliente/grabar/" + paramsUsuario.username}>Grabar</a></li> */}
                            <li className="nav-item"> <a className="nav-link text-light" href={"/cliente/pagos/" + paramsUsuario.username}>Mis pagos</a></li>
                            <li className="nav-item"> <a className="nav-link text-light" href={"/cliente/partidos/" + paramsUsuario.username}>Grabar partidos</a></li>
                            <li className="nav-item"> <a className="nav-link text-light" href={"/cliente/mis-videos/" + paramsUsuario.username + "/1"}>Mis Videos</a></li>
                        </ul>




                        {/* Segunda parte del menú */}
                        <div className="col-md-4 text-md-end text-center headerFinal">
                            <div>
                                <a href={"/cliente/mi-cuenta/" + paramsUsuario.username} className="btn btn-outline-w me-2" role="button" >Mi cuenta</a>
                            </div>
                            <div>
                                <form>
                                {/* <a href="/logout" className="btn btn-primary" role="button" >Cerrar Sesión</a> */}
                                <button onClick={handleLogOut} className="btn btn-primary-w" type="submit">Cerrar Sesión</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default HeaderUsuario;

import '../estilos/paginas.css';

function PagaYgrabaBoton() { 
    return(
    <div class="paga-button">
          <a href="/paga-y-graba" >
              <img src="/imagenes/marca/pagaygraba.png" alt="Paga y graba" />
          </a>
      </div>
    )
}



export default PagaYgrabaBoton;
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import '../estilos/partials.css';

function SidebarUsuario() {
    const paramsUsuario = useParams(); // obtenemos params para poner en url
    const navigate = useNavigate();


    function handleLogOut(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        localStorage.clear(); // limpiamos el almacentaiento local.
        axios.post("/logout")
        .then(function(response){
            console.log(response);
            navigate("/");
        });
    }

    return(
        <div id='miSidebar' className="d-flex flex-column flex-shrink-0" style={{width: "4.5rem"}}>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
            <li className="nav-item">
                <a  href={"/cliente/home/" + paramsUsuario.username + "/NA/NA/NA/1"} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" aria-current="page" title="Dashboard" data-bs-toggle="tooltip" data-bs-placement="right">
                <i className="bi bi-camera-reels" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li>
            <li>
                <a href={"/cliente/pagos/" + paramsUsuario.username} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Pagos" data-bs-toggle="tooltip" data-bs-placement="right">
                <i className="bi bi-cart-check" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li>
            <li>
                <a href={"/cliente/partidos/" + paramsUsuario.username} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Grabar" data-bs-toggle="tooltip" data-bs-placement="right">
                <i className="bi bi-camera-reels" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li>
            <li>
                <a href={"/cliente/mis-videos/" + paramsUsuario.username + "/1"} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Mis videos" data-bs-toggle="tooltip" data-bs-placement="right">
                <i className="bi bi-file-earmark-play" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li>
            {/* <li>
                <a  href={"/cliente/pagos/" + paramsUsuario.username} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="FAQ" data-bs-toggle="tooltip" data-bs-placement="right">
                <i className="bi bi-credit-card-2-front" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li> */}
            {/* <li>
                <a  href={"/cliente/soporte/" + paramsUsuario.username} className="nav-link py-3 border-bottom border-dark-subtle rounded-0" title="Contacto" data-bs-toggle="tooltip" data-bs-placement="right">
                <i className="bi bi-patch-question" style={{fontSize: "25px", color: "white"}}></i>
                </a>
            </li> */}
            </ul>



            <div className="dropdown border-top">
            <a href={"/cliente/home/" + paramsUsuario.username}  className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="/imagenes/marca/favicon.ico" alt="mdo" width="24" height="24" className="rounded-circle" />
            </a>
            <ul className="dropdown-menu text-small shadow">
                <li><a className="dropdown-item" href={"/cliente/mi-cuenta/" + paramsUsuario.username}>Mi cuenta</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li>
                    <form>
                    <button onClick={handleLogOut} className="btn btn-light" type="submit">Cerrar Sesión</button>
                    </form>
                </li>
            </ul>
            </div>
        </div>
    )
}

export default SidebarUsuario;
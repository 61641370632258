
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SidebarCamarografo from "../../partials/sidebarCamarografo";
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import Etiquetas from "./etiquetas";
import SubirVideo from "./subirVideo";

function GrabarVozPorSolicitud(){

    
    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {username, pagina} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal] = useState({}); // Variables localstorage
    const [videosPartido, setVideosPartido] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [siguientePagina, setSiguientePagina] = useState(null);
    const [paginaAnterior, setPaginaAnterior] = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
    const [videos, setVideos] = useState(null);
    const urlVolver   = "/camarografo/home/" + username;

    

    
    useEffect(() => {

        async function obtenerVideosGrabar(){
            try {
                await axios.post('/obtener-videos-grabar-voz-solicitud', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: {  }
                })
                .then(async function(response){
                    if(response.status===200){   setVideos(response.data.videos);   }
                    else{   console.log(response.data.mensaje);   }
                });
            } 
            catch (error) { console.error('Error uploading video', error); }
        }
        obtenerVideosGrabar();




        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);
    

    
    console.log(videos);
    
    
    async function cancelarSolicitud(datos) {
        setCargando(true);

        let urlVideo  = datos.urlVideo;
        let idVideo   = datos.idVideo;
        let idCLV     = datos.idCLV;
        let idMensaje = datos.idMensaje;

        try {
            await axios.post('/cancelar-mensaje', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  idCLV: idCLV, urlVideo: urlVideo, idVideo: idVideo, idMensaje: idMensaje }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert('Solicitud cancelada correctamente.'); 
                }
                else{
                    window.location.reload(); alert(response.data.mensaje);
                }
            });
        } 
        catch (error) {   setCargando(false); alert("error cancelando la solicitud"); console.error('Error cancelando el video', error); }
    }

    
    async function anadirVoz(datos){
        let urlAver = "/camarografo/anadir-voz/" + routeParams.username;
        
        let urlVideo  = datos.urlVideo;
        let idVideo   = datos.idVideo;
        let idCLV     = datos.idCLV;
        let idMensaje = datos.idMensaje;
        let video     = datos.video;
        let texto     = datos.texto;

        navigate(urlAver, {state: { urlVideo: urlVideo, idVideo: idVideo, idCLV: idCLV, idMensaje: idMensaje, video: video, texto: texto } });
    }

    
    


    return (
        <div className=''>

            <div className='d-flex flex-row '>
                {/* BARRA LATERAL */}
                <SidebarCamarografo />
    
                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark ">
                        <h1 className="h2">Grabar voz</h1>
                    </div>
     
                    {(videos)
                        ? <div>
                           
                            
                            {/* El total de columnas es: largo del arreglo / 4 */}
                            
                            <div id="contenedorVideos" className="row">
                                {(videos.length > 0)
                                    ? videos.map((videoActual, index) => (
                                        (videoActual.video.urlVideo !== "NA")
                                            ? <div key={index} className="col-md-3 mb-5 d-flex flex-column align-items-center">
                                                <div className="contenedor-video-grabacion">
                                                    <video
                                                        id={`video-${index}`}
                                                        className="video-js vjs-default-skin videoEnvVerVideos"
                                                        controls
                                                        preload="auto"
                                                        poster={videoActual.video.urlImagen}
                                                        data-setup="{ddd}"
                                                    >
                                                        <source src={`${videoActual.video.urlVideo}?v=${new Date().getTime()}`}  type="video/MP4" />
                                                    </video>

                                                    {/* Descripción del video */}
                                                    <p className="text-center mt-1" style={{fontSize: "13px"}}>id: {videoActual.video.idVideo}</p>
                                                    <div className="d-flex justify-content-center align-items-center my-2">
                                                        <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoActual.video.equipo}</strong></p>
                                                        <p className="textosDescripcionUno text-center me-2 mb-0">{videoActual.video.tipo}</p>
                                                        
                                                        <div className="d-flex align-items-center me-2">
                                                            <i className="textosDescripcionUno bi bi-heart me-1"></i>
                                                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.meGusta.length}</p>
                                                        </div>
                                                        
                                                        <div className="d-flex align-items-center me-2">
                                                            <i className="textosDescripcionUno bi bi-play me-1"></i>
                                                            <p className="textosDescripcionUno text-center mb-0">{videoActual.video.vistas}</p>
                                                        </div>
                                                    </div>

                                                    {/* Solicitud envaida por */}
                                                    <div>
                                                        <p className="text-center"><i>Enviado por: <strong>{videoActual.username}</strong></i></p>
                                                    </div>

                                                    
                                                    {/* Botón añadir voz */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            video: videoActual,
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo,
                                                            idCLV: videoActual.idCLV,
                                                            idMensaje: videoActual.idMensaje,
                                                            texto: videoActual.mensaje,
                                                            };
                                                            anadirVoz(datosAdicionales);
                                                        }}
                                                    >
                                                        Añadir Voz
                                                    </button>
                                                    {/* Botón cancelar solicitud */}
                                                    <button       
                                                        className="w-100 mt-1 btn btn-danger"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.video.idVideo,
                                                            urlVideo: videoActual.video.urlVideo,
                                                            idCLV: videoActual.idCLV,
                                                            idMensaje: videoActual.idMensaje,
                                                            };
                                                            cancelarSolicitud(datosAdicionales);
                                                        }}
                                                    >
                                                        Cancelar solicitud
                                                    </button>
                                                   
                                                </div>
                                            </div>
                                            : null
                                    ))
                                    : <p className="text-center my-5" >No hay videos en edición.</p>
                                }
                            </div>

                            
                        </div>
                        : <p className="text-center my-5" >Aún no hay solcitudes de grabación.</p>
                    }
                </div>
            </div>


            {/* zona cargando */}
            {cargando && (
                <div className="overlay">
                <div className="loader"></div>
                <p className="loadingText">Completando proceso, por favor espere...</p>
                </div>
            )}
        </div>
    )
    
    
}

export default  GrabarVozPorSolicitud;

import styles from '../estilos/inicioRegistro.module.css';
import {useLocation, useNavigate} from 'react-router-dom';

 
function AlertaAntesDeGrabar() {
  const navigate    = useNavigate();
  const location = useLocation();
  const datosPagina = location.state.datosPagina;


  // Boton con datos - redirección con datos
  async function funcionBotonDos() {
    return navigate(location.state.urlBotonDos, {
    state: datosPagina
    });
  }

  return(
    <div className={styles.containerAlertaDos} >
      
      <h2 className=" mb-5 text-light">Recomendaciones para grabar</h2>


      <h4 className='text-light'>1. Gira tu celular</h4>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <i style={{ color: 'white', fontSize: '25px', marginRight: '10px'  }} class="bi bi-arrow-repeat"></i>
        <p className='text-light' style={{ textAlign: 'justify', margin: '0' }}>Para grabar horizontalmente</p>
      </div>


      {/* Navegadores */}
      <h4 className='text-light mt-4'>2. Usa los navegadores</h4>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src='/imagenes/webGlobal/chrome.png' style={{ width: '30px', marginRight: '10px' }} alt='google'></img>
        <p className='text-light' style={{ textAlign: 'justify', margin: '0' }}>Google chrome</p>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src='/imagenes/webGlobal/safari.png' style={{ width: '30px', marginRight: '10px' }} alt='google'></img>
        <p className='text-light' style={{ textAlign: 'justify', margin: '0' }}>Safari</p>
      </div>



      {/* 2. Acepta permisos */}
      <h4 className='text-light mt-4'>3. Acepta permisos</h4>

      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2px' }}>
        <i style={{ color: 'white', fontSize: '25px', marginRight: '20px' }} class="bi bi-camera"></i>
        <i style={{ color: 'white', fontSize: '25px' }} class="bi bi-mic"></i>
      </div>
      <p className='text-light' style={{ textAlign: 'center', margin: '0' }}> 
        Permite el acceso a cámara y micrófono. <strong>Si no ves la solicitud,</strong> actívalo manualmente en tu dispositivo.
      </p>


      {/* 3. Acepta permisos */}
      <h4 className='text-light mt-4'>4. Conexión a internet</h4>
      <p className='text-light' style={{ textAlign: 'center', margin: '0' }}> 
        Verifica que la velocidad de carga sea igual o mayor a 20 mbps
      </p>


      {/* Botones */}
      <a target={location.state.urlAfueraDeWeb ? "_blank" : "_self"}  href="https://fast.com/es/" rel="noreferrer" className="btn btn-secondary mt-5 mb-3" role='button'>Test de velocidad</a> 
      {(location.state.mostrarBotonDos) ? <button onClick={funcionBotonDos} className="btn btn-warning btn-lg" >{location.state.nombreBotonDos}</button> : null }
      
    </div>
  )
}


export default AlertaAntesDeGrabar;
import '../../estilos/paginas.css';


function RegistrarParaVer() {

    return (
        <div className='container my-5'>
            {/* <hr className="featurette-divider" /> */}
            <h5 className='text-center mb-3'>¿Quieres ver más? haz click y regístrate o inicia sesión</h5>
            <a class="btn btn-primary w-100 mb-2" href="/registrar" role="button">Registrar</a>
            <a class="btn btn-secondary w-100" href="/iniciar-sesion" role="button">Iniciar Sesión</a>
        </div>
    )
}



export default RegistrarParaVer;
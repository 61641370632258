import React, {useState} from "react";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function CrearPartidoPago(props) {

    const { pago, username, equipos, estadios } = props; // contiene la url para volver: /cliente/mi-cuenta/eduardo2
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    const[inputDos, setInputDos] = useState ({
        textoEquipoUno: "",
        textoEquipoDos: "",
        deporte: "",
        textoNombreEstadio: "",
        textoCiudadEstadio: "",
        textoPaisEstadio: "",
        dropEquipoUno: "",
        dropEquipoDos: "",
        dropEstadio: ""
    });
    
    function handleChange2(event) {
        const { name, value } = event.target;

        // Expresión regular para permitir solo letras y números, sin espacios
        //const validInputRegex = /^[a-zA-Z0-9\s]*$/;
        const validInputRegex = value.replace(/[^A-Za-z0-9\s]/g, '');

        // Validar el valor antes de actualizar el estado
        setInputDos(prevInputDos => {
            return {
                ...prevInputDos,
                [name]: validInputRegex
            };
        });
        
    }


    


    async function crearPartidoConPago(event){
        event.preventDefault();
        // dROPDWON EQUIPOS
        let dropE1 = inputDos.dropEquipoUno;
        let dropE2 = inputDos.dropEquipoDos;
        // INPUTS EQUIPOS
        let inputE1 = inputDos.textoEquipoUno;
        let inputE2 = inputDos.textoEquipoDos;
        // DROPDOWN ESTADIO
        let dropEstadio = inputDos.dropEstadio;
        // INPUT ESTADIO
        let inputNombreE = inputDos.textoNombreEstadio;
        let inputCiudadE = inputDos.textoCiudadEstadio;
        let inputPaisE   = inputDos.textoPaisEstadio;
        // DROPDOWN DEPORTE
        let dropDeporte  = inputDos.deporte;

        

        if(dropDeporte===""){  window.location.reload(); alert("Debes seleccionar un deporte"); }
        // Verificar caso 1: Equipos seleccionados del dropdown + estadio seleccionado del dropdown
        if (dropE1 !== "" && dropE2 !== "" && dropEstadio !== "" && inputNombreE === "" && inputCiudadE === "" && inputPaisE === "") {
            try{
                console.log("Opción 1: Usando equipos del dropdown y estadio del dropdown");
                
                //1. CREAR PARTIDO COMPLETO
                await axios.post("/crear-partido-pago", {
                    headers: { 'Content-Type': 'multipart/form-data',},
                    params: {  idEquipoUno: dropE1, idEquipoDos: dropE2, deporte: dropDeporte, idEstadio: dropEstadio, pago: pago }
                })
                .then(function(response){  
                    if(response.status===200){ window.location.reload(); alert("Partido creado exitosamente"); }  
                    else{window.location.reload(); alert("Error. Por favor volver a intentar");}
                });
            }
            catch (error) {  window.location.reload(); alert("Error 1. Por favor vuelva a intentar."); console.error("Error en condición 1:", error); }

        }
        // Verificar caso 2: Equipos seleccionados del dropdown + estadio escrito manualmente
        else if (dropE1 !== "" && dropE2 !== "" && dropEstadio === "" && inputNombreE !== "" && inputCiudadE !== "" && inputPaisE !== "") {
            try{
                console.log("Opción 2: Usando equipos del dropdown y estadio escrito manualmente");
                
                // 1. CREAR ESTADIO ´
                let objetoEstadio = { nombre: inputNombreE, pais: inputPaisE, ciudad: inputCiudadE };
                await axios.post("/crear-estadio", objetoEstadio)
                .then(async function(response){ 
                    if(response.status===200){ 

                        console.log("estadio Creado. Id: ", response.data.idEstadio, ". Iniciando a crear el partido completo"); 
                        // 2. CREAR PARTIDO COMPLETO
                        await axios.post("/crear-partido-pago", {
                            headers: { 'Content-Type': 'multipart/form-data',},
                            params: {  idEquipoUno: dropE1, idEquipoDos: dropE2, deporte: dropDeporte, idEstadio: response.data.idEstadio, pago: pago }
                        })
                        .then(function(response){  
                            if(response.status===200){ window.location.reload(); alert("Partido creado exitosamente"); }  
                            else{window.location.reload(); alert("Error. Por favor volver a intentar");}
                        });
                    }
                    else{ window.location.reload(); alert("No se pudo crear el estadio. Por favor intenta de nuevo"); }
                });
            }
            catch (error) {  window.location.reload(); alert("Error 2. Por favor vuelva a intentar."); console.error("Error en condición 2:", error); }
        }
        // Verificar caso 3: Equipos escritos manualmente + estadio seleccionado del dropdown
        else if (dropE1 === "" && dropE2 === "" && inputE1 !== "" && inputE2 !== "" && dropEstadio !== "" && inputNombreE === "" && inputCiudadE === "" && inputPaisE === "") {
            try{
                console.log("Opción 3: Usando equipos escritos y estadio del dropdown");
                
                // 1. CREAR EQUIPOS
                const datosAenviarUno = {deporte: dropDeporte, nombre: inputE1}; // se arma mismo input para crear equipos sin image, de crear equipo jsx 
                const datosAenviarDos = {deporte: dropDeporte, nombre: inputE2}; // se arma mismo input para crear equipos sin image, de crear equipo jsx 
                await axios.post("/crear-equipo-sin-imagen", {
                    headers: { 'Content-Type': 'multipart/form-data',},
                    params: {  datos: datosAenviarUno }
                })
                .then(async function(responseUno){  if(responseUno.status===200){ 
                    let idEquipoUno = responseUno.data.idEquipo;
                    console.log("Equipo Uno creado. ", idEquipoUno);
                    
                    await axios.post("/crear-equipo-sin-imagen", {
                        headers: { 'Content-Type': 'multipart/form-data',},
                        params: {  datos: datosAenviarDos }
                    })
                    .then(async function(responseDos){  if(responseDos.status===200){ 
                        let idEquipoDos = responseDos.data.idEquipo;
                        console.log("Equipo Dos creado. ", idEquipoDos);

                        // 2.CREAR PARTIDO COMPLETO
                        await axios.post("/crear-partido-pago", {
                            headers: { 'Content-Type': 'multipart/form-data',},
                            params: {  idEquipoUno: idEquipoUno, idEquipoDos: idEquipoDos, deporte: dropDeporte, idEstadio: dropEstadio, pago: pago }
                        })
                        .then(function(response){  
                            if(response.status===200){ window.location.reload(); alert("Partido creado exitosamente"); }  
                            else{window.location.reload(); alert("Error. Por favor volver a intentar");}
                        });
                    }
                    }); 
                }  
                });
            }
            catch (error) {  window.location.reload(); alert("Error 3. Por favor vuelva a intentar."); console.error("Error en condición 3:", error); }
        }
        // Verificar caso 4: Equipos escritos manualmente + estadio escrito manualmente
        else if (dropE1 === "" && dropE2 === "" && inputE1 !== "" && inputE2 !== "" && dropEstadio === "" && inputNombreE !== "" && inputCiudadE !== "" && inputPaisE !== "") {
            try{
                console.log("Opción 4: Usando equipos y estadio escritos manualmente");
                
                // 1. CREAR EQUIPOS
                const datosAenviarUno = {deporte: dropDeporte, nombre: inputE1}; // se arma mismo input para crear equipos sin image, de crear equipo jsx 
                const datosAenviarDos = {deporte: dropDeporte, nombre: inputE2}; // se arma mismo input para crear equipos sin image, de crear equipo jsx 
                await axios.post("/crear-equipo-sin-imagen", {
                    headers: { 'Content-Type': 'multipart/form-data',},
                    params: {  datos: datosAenviarUno }
                })
                .then(async function(responseUno){  if(responseUno.status===200){ 
                    let idEquipoUno = responseUno.data.idEquipo;
                    console.log("Equipo Uno creado. ", idEquipoUno);
                    
                    await axios.post("/crear-equipo-sin-imagen", {
                        headers: { 'Content-Type': 'multipart/form-data',},
                        params: {  datos: datosAenviarDos }
                    })
                    .then(async function(responseDos){  if(responseDos.status===200){ 
                        let idEquipoDos = responseDos.data.idEquipo;
                        console.log("Equipo Dos creado. ", idEquipoDos);

                        //2. CREAR ESTADIO
                        let objetoEstadio = { nombre: inputNombreE, pais: inputPaisE, ciudad: inputCiudadE };
                        await axios.post("/crear-estadio", objetoEstadio)
                        .then(async function(responseTres){ if(responseTres.status===200){ 
        
                            console.log("estadio Creado. Id: ", responseTres.data.idEstadio, ". Iniciando a crear el partido completo"); 
                            
                            // 2. CREAR PARTIDO COMPLETO
                            await axios.post("/crear-partido-pago", {
                                headers: { 'Content-Type': 'multipart/form-data',},
                                params: {  idEquipoUno:  idEquipoUno, idEquipoDos:  idEquipoDos, deporte: dropDeporte, idEstadio: responseTres.data.idEstadio, pago: pago }
                            })
                            .then(function(response){  
                                if(response.status===200){ window.location.reload(); alert("Partido creado exitosamente"); }  
                                else{window.location.reload(); alert("Error. Por favor volver a intentar");}
                            });
                        }
                        });
                    }
                    }); 
                }  
                });
            }
            catch (error) {  window.location.reload(); alert("Error 4. Por favor vuelva a intentar."); console.error("Error en condición 4:", error); }
        }
        // Si ninguna de las condiciones anteriores se cumple, generar un error
        else {
            console.log("Error: Debes seleccionar o ingresar ambos equipos y un estadio válido (dropdown o manualmente).");
            window.location.reload();
            alert("Error: Debes seleccionar o ingresar ambos equipos y un estadio válido (dropdown o manualmente)");
        }    
    }

   
   

    return(
        <div>
            <Button 
                disabled={pago.idPartido !== -1 || !(pago.estado === "approved" && pago.detalle_estado === "accredited")} 
                variant="warning w-100 mt-1" 
                onClick={handleShow}
            >
                {(pago.idPartido === -1 || !(pago.estado === "approved" && pago.detalle_estado === "accredited")) ? "Crear partido" : "No puedes crear el partido"}
            </Button>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Crear partido</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>

                    <h6 className="mb-3">1. Crea o selecciona los equipos</h6>
                    <form>
                        {/* SELECCIONAR EQUIPOS /////////////////// */}
                        {/* ESCOGE EQUIPO UNO */}
                        <div className="form-floating mb-2">
                            <select onChange={handleChange2} className="form-select" id="dropEquipoUno" name="dropEquipoUno" value={inputDos.dropEquipoUno} aria-label="Seleccione equipo Uno">
                                <option value="">Seleccionar equipo uno</option>
                                {equipos.map(equipo => (
                                    <option key={equipo.idEquipo} value={equipo.idEquipo}>{equipo.nombre + " - " + equipo.deporte}</option>
                                ))}
                            </select>
                            <label htmlFor="dropEquipoUno">Equipo Uno</label>
                        </div>

                        {/* ESCOGE EQUIPO DOS */}
                        <div className="form-floating">
                            <select onChange={handleChange2} className="form-select" id="dropEquipoDos" name="dropEquipoDos" value={inputDos.dropEquipoDos} aria-label="Seleccione equipo Dos">
                                <option value="">Seleccionar equipo dos</option>
                                {equipos.map(equipo => (
                                    <option key={equipo.idEquipo} value={equipo.idEquipo}>{equipo.nombre}</option>
                                ))}
                            </select>
                            <label htmlFor="dropEquipoDos">Equipo Dos</label>
                        </div>

                        <p style={{fontSize: "15px"}} className="text-center my-3">¿No existen tus equipos? ¡Créalos aquí mismo!</p>

                        
                        {/* CREAR EQUIPOS /////////////////// */}
                        {/* INPUT EQUIPO UNO */}
                        <div className="form-floating mb-2">
                            <input onChange={handleChange2}  name="textoEquipoUno" value={inputDos.textoEquipoUno} type="text" className="form-control" id="floatingText" placeholder="Equipo Uno"  pattern="[A-Za-z0-9\s]*" />
                            <label htmlFor="floatingText">Equipo Uno</label>
                        </div>

                        {/* INPUT EQUIPO DOS */}
                        <div className="form-floating mb-5">
                            <input onChange={handleChange2}  name="textoEquipoDos" value={inputDos.textoEquipoDos} type="text" className="form-control" id="floatingText" placeholder="Equipo Dos" />
                            <label htmlFor="floatingText">Equipo Dos</label>
                        </div>


                            


                        {/* SELECCIONAR ESTADIO /////////////////// */}
                        {/* Estadios */}
                        <h6 className="">2. Escoge o crea un estadio/cancha</h6>
                        <div className="form-floating">
                            <select onChange={handleChange2} className="form-select" id="dropEstadio" name="dropEstadio" value={inputDos.dropEstadio} aria-label="Seleccione estadio">
                            <option value="">Seleccionar estadio</option>
                            {estadios.map(estadio => (
                                <option key={estadio.idEstadio} value={estadio.idEstadio}>{estadio.nombre + ". " + estadio.ciudad + ", " + estadio.pais }</option>
                            ))}
                            </select>
                            <label htmlFor="dropEstadio">Estadio</label>
                        </div>
                        
                        <p style={{fontSize: "15px"}} className="text-center my-3">¿No existe tus estadio o cancha? ¡Créalo aquí mismo!</p>

                        {/* INPUT NOMBRE ESTADIO */}
                        <div className="form-floating mb-2">
                            <input onChange={handleChange2}  name="textoNombreEstadio" value={inputDos.textoNombreEstadio} type="text" className="form-control" id="floatingText" placeholder="Nombre de estadio" />
                            <label htmlFor="floatingText">Nombre de estadio</label>
                        </div>

                        <div className="form-floating mb-2">
                            <input onChange={handleChange2}  name="textoCiudadEstadio" value={inputDos.textoCiudadEstadio} type="text" className="form-control" id="floatingText" placeholder="Ciudad de estadio" />
                            <label htmlFor="floatingText">Ciudad Estadio</label>
                        </div>

                        <div className="form-floating mb-2">
                            <input onChange={handleChange2}  name="textoPaisEstadio" value={inputDos.textoPaisEstadio} type="text" className="form-control" id="floatingText" placeholder="País de estadio" />
                            <label htmlFor="floatingText">País Estadio</label>
                        </div>





                        {/* deporte */}
                        <h6 className="mt-5 mb-3">3. Escoge el deporte del partido</h6>
                        <div className="form-floating my-3">
                            <select onChange={handleChange2} value={inputDos.deporte} name="deporte" className="form-select" id="floatingCategoria">
                                <option value="">Selecciona un deporte</option>
                                <option value="futbol">Fútbol</option>
                                <option value="basquetball">Basquetball</option>
                                <option value="rugby">Rugby</option>
                            </select>
                            <label htmlFor="floatingCategoria">Deporte</label>
                        </div>

                        

                        <button type="submit" className="btn btn-warning w-100 mt-5" onClick={crearPartidoConPago}>Crear partido</button>

                    </form>


                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CrearPartidoPago;
import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function EditarTorneo(){
    const navigate = useNavigate();
    const routeParams = useParams();
    const location = useLocation();
    const idTorneo = location.state.idTorneo;

    const [cargando, setCargando]          = useState(false);
    const [infoUsuarioLocal, setInfoLocal]          = useState({}); // Variables localstorage
    const [torneo, setTorneo]                       = useState([]);
    const [partidosSinEditar, setPartidosSinEditar] = useState([]);
    const [partidosEditados, setPartidosEditados]   = useState([]);
    const [equipos, setEquipos]                     = useState([]);
    const [estadios, setEstadios]                   = useState([]);
    const [startDate, setStartDate]                 = useState(new Date());
    const [archivo, setArchivo]                     = useState(null); // imagen subida
    const urlVolver   = "/admin/crear-torneo/" + routeParams.username + "/1";

    
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

 
        async function ObtenerDatosDeTorneo(){
            setCargando(true);
            let datos = { idTorneo: idTorneo }
            await axios.post("/obtener-torneo-individual", datos)
            .then(async function(response){
                setCargando(false);
                setTorneo(response.data.torneo);
                setPartidosSinEditar(response.data.partidosSinEditar);
                setPartidosEditados(response.data.partidosEditados);
            });
        }
        ObtenerDatosDeTorneo();

        async function ObtenerEquipos(){
            await axios.post("/obtener-equipo-para-editar")
            .then(async function(response){
                setEquipos(response.data.equipos);
            });
        }
        ObtenerEquipos();

        async function ObtenerEstadios(){
            await axios.post("/obtener-estadios-para-editar")
            .then(async function(response){
                setEstadios(response.data.estadios);
            });
        }
        ObtenerEstadios();


        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


   
    
    
    async function funcionEditarPartido(datos){

        
        
        if(datos.idEquipoUno==="" || datos.idEquipoDos==="" || datos.idEstadio===""){
            navigate("/alerta", {state: {titulo:"Ingresar datos", mensaje:"Debes ingresar todos los datos para proceder.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        else if(datos.idEquipoUno===datos.idEquipoDos){
            navigate("/alerta", {state: {titulo:"Mismo equipo", mensaje:"Debes escoger equipos diferentes para proceder.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        else{
            await axios.post("/editar-partido", datos)
            .then(function(response){
                if(response.data.modificado===true){
                    window.location.reload();
                }
            });
        }
    }

    async function eliminarPartido(datos){
        console.log(datos);

        await axios.post("/eliminar-partido", datos)
        .then(function(response){
            if(response.data.status===200){
                window.location.reload();
                alert("Partido eliminado");
            }
        });
    }

    // FORMULARIO /////////////////////////////
    const[input, setInput] = useState ({
        descripcion: "",
    });

    function handleChange(event){
        const {name, value} = event.target;
    
        setInput(prevInput => { return { ...prevInput, [name]: value } });
    }
    // FORMULARIO /////////////////////////////
    
    async function anadirDescripcion(event){
        event.preventDefault();

        let descripcion = input.descripcion;

        await axios.post("/anadir-descripcion", {descripcion, idTorneo})
        .then(function(response){
            if(response.data.status===200){
                window.location.reload();
                alert("Descripción añadida.");
            }
        });
    }

    function controlCambioVideo(event){
        //console.log(event);
        const archivoSubido = event.target.files[0]; //la imagen está en la posición 0 del arreglo files
        if(archivoSubido){
            setArchivo(archivoSubido);
        }
    }

    async function anadirVideo(event){
        setCargando(true);
        event.preventDefault();
        const formData = new FormData(); // Creamos formData para pasar la imagen sin problema.
        formData.append('file', archivo); // guardamos imagen en 'file'

    

        await axios.post("/anadir-video-torneo", formData, {
            params: {  idTorneo:  idTorneo, dato: "otro datos"  }
        })
        .then(function(response){
            setCargando(false);
            if(response.data.status ===200){
                window.location.reload();
                alert("Video añadido.");
            }
            else{
                window.location.reload();
                alert("error. Vuelva a intentar");
            }
        });
    }
    
    
     
    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Editar torneo</h1>
                    </div>
            

                     

                    {/* Formulario crear equipos */}
                 


                    <div className='container'>
                        <div className="p-5 my-4 bg-body-tertiary rounded-3">
                            <div className="row  d-flex align-items-center" style={{ minHeight: "400px" }}>
                                {/* columna 1 */}
                                <div className="col-lg-6 d-flex justify-content-center">
                                        <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={(torneo) ? torneo.imagen : ""}  alt="" /></div>
                                </div>

                                {/* Columna 2 */}
                                <div className="col-lg-6 d-flex justify-content-center">
                                    <div className="container-fluid py-5">
                                        <h2 className="">Torneo {(torneo) ? torneo.nombre : ""}</h2>
                                        <p className="col-md-8">Deporte: {(torneo) ? torneo.deporte : ""}</p>
                                        
                                        
                                        {(torneo.video && torneo.video!=="")
                                        ? <video
                                            className="video-js vjs-default-skin videoIndividual"
                                            preload="auto"
                                            controls
                                            data-setup="{}"
                                            style={{ height: "200px"}}
                                            onError={(e) => console.error("Error al cargar el video:", e)}
                                        >
                                            <source src={torneo.video} type="video/mp4" />
                                        </video>
                                        : null
                                        }
                                        
                                    </div>
                                </div>
                            </div>


                            <p className="">{(torneo) ? torneo.descripcion : ""}</p>

                            {/* INPUTS */}
                            {/* Input texto descripción */}
                            <form className="mt-5">
                                {/* Texto descripción del torneo */}
                                <div className="form-floating mb-3">
                                    <textarea className="form-control" onChange={handleChange} name="descripcion" value={input.descripcion}  placeholder="Escribe una descripción" id="floatingTextarea2" style={{height: "100px"}}></textarea>
                                    <label htmlFor="floatingTextarea2">Escribe una descripción</label>
                                </div>

                                 {/* Botón enviar */}
                                 <button onClick={anadirDescripcion} type="submit" style={{width: "100%"}} className="btn btn-primary">Añadir descripción</button>
                            </form>

                            {/* Input video */}
                            <form>
                                <div className="form-floating my-3">
                                    <input onChange={controlCambioVideo} className="form-control" type="file" id="formFile" /> 
                                    <label htmlFor="floatingInput">Video</label>
                                    <button onClick={anadirVideo} type="submit" style={{width: "100%"}} className="btn btn-primary mt-3">Añadir video</button>
                                </div>
                            </form>
                        </div>
                    </div>



                    {/* PARTIDOS SIN EDITAR */}
                    <h2 style={{textAlign: "center"}} className=" mb-2 mt-5">Partidos sin editar</h2>
                    <div className="overflow-auto p-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                       
                        { (partidosSinEditar.length>0)
                        ? partidosSinEditar.map( (partidoActual) => (
                            <div key={partidoActual._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">
    
                                {/* idPartido  */}
                                <div className="col-sm-1">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.idPartido}  />
                                        <label htmlFor="floatingInputGrid">id</label>
                                    </div>
                                </div>

                                                                
                                {/* Selección Fecha */}
                                <div className="col-sm-1">
                                    <div className="form-floating">
                                        <select
                                            className="form-select"
                                            id={`fecha_${partidoActual.idPartido}`}
                                            aria-label="Seleccione fecha"
                                        >
                                            <option value="">Fecha</option>
                                            {Array.from({ length: torneo.cantidadDeFechas }, (_, index) => (
                                                <option key={index + 1} value={index + 1}>
                                                    {index + 1}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor={`fecha_${partidoActual.idPartido}`}>Fecha</label>
                                    </div>
                                </div>

                                {/* Selección equipo 1 */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                            <select className="form-select" id={`equipoUno_${partidoActual.idPartido}`} aria-label="Seleccione equipo">
                                            <option value="">Seleccionar equipo</option>
                                            {equipos.map(equipo => (
                                                <option key={equipo.idEquipo} value={equipo.idEquipo}>{equipo.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor={`equipoUno_${partidoActual.idPartido}`}>Equipo 1</label>
                                    </div>
                                </div>

                                {/* Selección equipo 2 */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                            <select className="form-select" id={`equipoDos_${partidoActual.idPartido}`} aria-label="Seleccione equipo">
                                            <option value="">Seleccionar equipo</option>
                                            {equipos.map(equipo => (
                                                <option key={equipo.idEquipo} value={equipo.idEquipo}>{equipo.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor={`equipoDos_${partidoActual.idPartido}`}>Equipo 2</label>
                                    </div>
                                </div>

                                {/* Selección estadio */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                            <select className="form-select" id={`estadio_${partidoActual.idPartido}`} aria-label="Seleccione estadio">
                                            <option value="">Seleccionar estadio</option>
                                            {estadios.map(estadio => (
                                                <option key={estadio.idEstadio} value={estadio.idEstadio}>{estadio.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor={`estadio_${partidoActual.idPartido}`}>Estadio</label>
                                    </div>
                                </div>
                                {/* Selección de fecha y hora */}
                                <div className="col-sm-2">
                                    <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    className="form-control"
                                    />
                                </div>

                                {/* Botón actualizar */}
                                <div className="col-sm-2">
                                    <button
                                    className="w-100 btn btn-primary"
                                    id={partidoActual.idPartido}
                                    onClick={(event) => {
                                        const idPartido = event.target.id;
                                        const datosAdicionales = {
                                        idPartido: event.target.id,
                                        fechaNumero: document.getElementById(`fecha_${idPartido}`).value,
                                        idEquipoUno: document.getElementById(`equipoUno_${idPartido}`).value,
                                        idEquipoDos: document.getElementById(`equipoDos_${idPartido}`).value,
                                        fecha: startDate,
                                        dia: startDate.getDate(),
                                        mes: startDate.getMonth() + 1,
                                        año: startDate.getFullYear(),
                                        hora: startDate.getHours(),
                                        minuto: startDate.getMinutes(),
                                        idEstadio: document.getElementById(`estadio_${idPartido}`).value,
                                        idTorneo: torneo.idTorneo,
                                        };
                                        funcionEditarPartido(datosAdicionales);
                                    }}
                                    >
                                    Actualizar Partido
                                    </button>
                                </div>
                            </div>
                        ))
                        : <p>Aún cargando</p>
                        }
                    </div>  

                    {/* PARTIDOS EDITADOS */}
                    <h2 style={{textAlign: "center"}} className=" mt-5 mb-2">Partidos editados</h2> 
                    <div className="overflow-auto p-3 mb-5 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                       
                        {(partidosEditados.length>0)
                        ? partidosEditados.map( (partidoActual) => (
                            <div key={partidoActual._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                {/* idPartido  */}
                                <div className="col-sm-1">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual._doc.idPartido}  />
                                        <label htmlFor="floatingInputGrid">id</label>
                                    </div>
                                </div>

                                {/* fecha  */}
                                <div className="col-sm-1">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.fechaNumero}  />
                                        <label htmlFor="floatingInputGrid">Fecha</label>
                                    </div>
                                </div>

                                {/* Equipo 1  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.nombreEquipoUno}  />
                                        <label htmlFor="floatingInputGrid">Equipo Uno</label>
                                    </div>
                                </div>

                                {/* Equipo 2  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.nombreEquipoDos}  />
                                        <label htmlFor="floatingInputGrid">Equipo Dos</label>
                                    </div>
                                </div>

                                {/* Estadio  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.nombreEstadio}  />
                                        <label htmlFor="floatingInputGrid">Estadio</label>
                                    </div>
                                </div>

                                {/* Fecha  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual._doc.fechaHora.dia + "/" + partidoActual._doc.fechaHora.mes + "/" + partidoActual._doc.fechaHora.año + ". " + partidoActual._doc.fechaHora.hora + ":" + partidoActual._doc.fechaHora.minuto}  />
                                        <label htmlFor="floatingInputGrid">Fecha</label>
                                    </div>
                                </div>


                                {/* Botón actualizar */}
                                <div className="col-sm-2">
                                    <button
                                    className="w-100 btn btn-danger"
                                    onClick={(event) => {
                                        const datosAdicionales = {
                                        idPartido: partidoActual._doc.idPartido,
                                        idTorneo: idTorneo
                                        };
                                        eliminarPartido(datosAdicionales);
                                    }}
                                    >
                                    Eliminar partido
                                    </button>
                                </div>

                            </div>
                        ))
                        :<p>aún no hay partidos</p>
                        } 
                    </div>


                    {/* zona cargando */}
                    {cargando && (
                        <div className="overlay">
                        <div className="loader"></div>
                        <p className="loadingText">Completando proceso, por favor espere...</p>
                        </div>
                    )}

                </div>
            </div>

            
        </div>
    )
}

export default EditarTorneo;
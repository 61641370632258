import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import RegistrarParaVer from './registrarParaVer';
 

function VerPartidoIndividual() {

  const navigate    = useNavigate();
  const location    = useLocation(); // cuando no se envía nada en state, location,state es null
  const routeParams = useParams();
  let { idPartido, nombrePartido }   = routeParams; // capturamos para paginación

  const [videos, setVideos] = useState(null);
  const [partido, setPartido] = useState(null);
  const [partidos, setPartidos] = useState(null);
  const [cargando, setCargando]  = useState(true);
  
  

  useEffect(() => {

    async function obtenerDeportes(){
      await axios.post("/obtener-partido-global", {
        params: {nombrePartido: nombrePartido, idPartido: idPartido, desde: "global"}
      })
      .then(async function(response){
        if(response.status===200){ 
          setCargando(false); // quitamos la espera
          setVideos(response.data.arregloFinal); setPartido(response.data.infoParido);
        }
        else{
          setCargando(false); // quitamos la espera
        }
      });
    }
    obtenerDeportes();





    // Modificar la clase de body par página de registro - styles.css /////////
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  
 
    

 
  function handleVideoClick(idVideo){
  let urlAverVideo = "/video/" + idVideo + "/" + nombrePartido; 

  // Navegar y forzar la recarga
  navigate(urlAverVideo, { replace: true });
  window.location.reload();  // Esto forzará la recarga completa de la página
  };

  
  return(
    <div className=''>
        
      {/* Información del partido */}
      {(partido && partido.length > 0)
        ? <div id="" className="container bg-body-tertiary p-5 my-4 rounded-3" >
        <div className="d-flex justify-content-center">
            <div className="row">
                <div className="col-md-6 text-center">
                    <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={partido[0].imagenEquipoUno}></img>
                </div>
                <div className="col-md-6 text-center">
                    <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={partido[0].imagenEquipoDos}></img>
                </div>
            </div>
        </div>
        <h2 className=' text-center'>{partido[0].nombreEquipoUno + " vs " + partido[0].nombreEquipoDos}</h2>
        <p  className=' text-center'>{partido[0].torneo}</p>
        <p  className=' text-center'>{partido[0].nombreEstadio}</p>
      </div>
        : null
      }
        


        
      {/* Videos */}
      <div className="container mt-5">
        <div id="contenedorVideos" className="row">
          {(videos && videos.length > 0) ? (
          videos.map((videoActual, index) =>
            videoActual.urlVideo !== "NA" ? (
            <div key={index} className="col-md-3 mb-5 d-flex flex-column align-items-center" >
                
              <div className="contenedor-video-grabacion position-relative" onClick={() => handleVideoClick(videoActual.video.idVideo)}>
                  {/* Envolver el video en un Link para redirigir */}
                  <video
                  id={`video-${index}`}
                  className="video-js vjs-default-skin videoEnvVerVideos"
                  preload="auto"
                  poster={(videoActual.video.urlImagen) ? videoActual.video.urlImagen : ""}
                  data-setup="{ddd}"
                  style={{ width: '100%' }}
                  >
                  <source
                      src={`${videoActual.video.urlVideo}?v=${new Date().getTime()}`}
                      type="video/MP4"
                  />
                  </video>
                  
                  {/* Ícono de Play superpuesto */}
                  <div className="overlay-play-icon">
                      <i className="bi bi-play-circle iconoReproducir" ></i>
                  </div>
              </div>

              {/* Descripción del video */}
              <div className="d-flex justify-content-center align-items-center my-2">
                  <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoActual.video.equipo}</strong></p>
                  <p className="textosDescripcionUno text-center me-2 mb-0">{videoActual.video.tipo}</p>
                  
                  <div className="d-flex align-items-center me-2">
                      <i className="textosDescripcionUno bi bi-heart me-1"></i>
                      <p className="textosDescripcionUno text-center mb-0">{videoActual.video.meGusta.length}</p>
                  </div>
                  
                  <div className="d-flex align-items-center me-2">
                      <i className="textosDescripcionUno bi bi-play me-1"></i>
                      <p className="textosDescripcionUno text-center mb-0">{videoActual.video.vistas}</p>
                  </div>
              </div>
            </div>
            ) : null
          )
          ) : (
          <p>Buscando partido.</p>
          )}
        </div>
      </div>
        

      <RegistrarParaVer />

      
      {/* zona cargando */}
      {cargando && (
          <div className="overlay">
          <div className="loader"></div>
          <p className="loadingText">Buscando torneo, por favor espere...</p>
          </div>
      )}

    </div>
  )
}



export default VerPartidoIndividual;